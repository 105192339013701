<template>
<div>
    <NavigationBar />
    <div class="container" style="padding-top: 20px;">
        <div class="alert alert-danger alert-dismissible d-flex align-items-center fade show" v-if="error">
            <i class="bi-exclamation-octagon-fill"></i>
            <strong class="mx-2">Error!</strong> {{ error }}
            <br />
        </div>
        <h2>Collections Report</h2>
        <div class="searchInputs">
            <div class="searchInputRow">
                <div class="searchInputItem">
                    <label for="startDate" class="col-form-label"><b>Start Date</b></label>
                    <input type="date" class="form-control" id="startDate" v-model="startDate" required>
                </div>
                <div class="searchInputItem">
                    <label for="endDate" class="col-form-label"><b>End Date</b></label>
                    <input type="date" class="form-control" id="endDate" v-model="endDate" required>
                </div>
            </div>
            <div class="searchInputRow">
                <div class="searchInputItem" v-if="regions">
                    <label for="region" class="col-form-label"><b>Region</b></label>
                    <select class="form-select" id="region" v-model="region">
                        <option value="-1" selected>Please select</option>
                        <option v-for="region in regions" :value="region.id" :key="region.id">{{region.name}}</option>
                    </select>
                </div>
                <div class="searchInputItem">
                    <label for="jamatkhana" class="col-form-label"><b>Jamatkhana</b> </label>
                    <select class="form-select" id="jamatkhana" v-model="jamatkhana" :disabled="region === '-1'">
                        <option v-if="regions" value="-1" selected>All Jamatkhanas</option>
                        <option v-for="jk in filterJamatkhanas(region)" :value="jk.id" :key="jk.id">{{jk.name}}</option>
                    </select>
                </div>
            </div>
            <div class="searchInputRow">
                <div class="searchInputItem">
                    <label for="paymentType" class="col-form-label"><b>Payment Type</b></label>
                    <select class="form-select" id="paymentType" v-model="paymentType">
                        <option value="-1" selected>Please select</option>
                        <option value=1>In Person</option>
                        <option value=2>Mail</option>
                    </select>
                </div>
            </div>
            <div class="searchInputRow">
                <div class="searchInputItem">
                    <button class="col-auto btn btn-success" :disabled="disableRunReport" @click="runReport">Run Report</button>
                </div>
            </div>
        </div>
        <div v-if="loadingCollectionReport">
            <LoadingSpinner />
        </div>
        <div v-if="collectionReport && !loadingCollectionReport">
            <div class="reportOptions">
                <button class="btn btn-primary" @click="printReport()">Print</button>
            </div>
            <h4>Collection Report for {{startDate}} - {{endDate}}</h4>
            <div class="table-responsive household-Member-Table pt-2" style="padding-bottom: 20px;" v-for="jk in collectionReport" :key="jk.jamatkhanaId">
                <table class="table">
                    <thead>
                        <tr><th class="table-primary" colspan="8">{{jk.jamatkhanaName}}</th></tr>
                    </thead>
                    <tbody v-for="volunteer in jk.volunteers" :key="volunteer.volunteerId">
                        <tr><td class="table-active" colspan="8"><b>Collected By: {{volunteer.volunteerName}}</b></td></tr>
                        <tr>
                            <td><b>Payment ID</b></td>
                            <td><b>Initiated At</b></td>
                            <td><b>Received At</b></td>
                            <td><b>Covered Members</b></td>
                            <td><b>Family Location</b></td>
                            <td><b>Payment Method</b></td>
                            <td><b>Payment Info</b></td>
                            <td><b>Total</b></td>
                        </tr>
                        <tr v-for="payment in volunteer.volunteerPayments" :key="payment.id">
                            <td><div class="link" @click="clickViewDetails(payment.id)">{{payment.id}}</div></td>
                            <td>{{getDatefromUtcTimeStamp(payment.paymentInitiatedAt)}}</td>
                            <td>{{getDatefromUtcTimeStamp(payment.paymentReceivedAt)}}</td>
                            <td>
                                <div v-for="member in payment.payment_members" :key="member.memberId">
                                    {{member.firstName}} {{member.lastName}}
                                </div>
                            </td>
                            <td>{{getJamatkhanaName(payment.familyLocationId)}}</td>
                            <td>{{getPaymentMethod(payment.paymentMethodId)}}</td>
                            <td>{{parsePaymentInfo(payment.paymentInfo)}}</td>
                            <td>{{displayInUSD(payment.total)}}</td>
                        </tr>
                        <tr>
                            <td colspan="6"><b>Totals</b></td>
                            <td><b>Count: {{volunteer.numberOfPayments}}</b></td>
                            <td><b>{{displayInUSD(volunteer.totalAmountCollected)}}</b></td>
                        </tr>
                        <tr><td colspan="8"></td></tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="6"><b>Grand Totals</b></td>
                            <td><b>Count: {{jk.numberOfPayments}}</b></td>
                            <td><b>{{displayInUSD(jk.totalAmountCollected)}}</b></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div v-if="collectionReport.length > 1">
                <table class="table">
                    <tbody>
                        <tr>
                            <td style="width: 12%"></td>
                            <td style="width: 12%"></td>
                            <td style="width: 12%"></td>
                            <td style="width: 12%"></td>
                            <td style="width: 12%"></td>
                            <td style="width: 12%"></td>
                            <td style="width: 12%"></td>
                            <td style="width: 12%"></td>
                            <td style="width: 12%"></td>
                        </tr>
                        <tr>
                            <td colspan="7"><b>Complete Grand Total</b></td>
                            <td><b>Count: {{totalPayments}}</b></td>
                            <td><b>{{displayInUSD(totalAmount)}}</b></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>    
    </div>
</div>

</template>

<script>
import axios from 'axios';
import NavigationBar from '../components/NavigationBar.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import { displayInUSD, getDatefromUtcTimeStamp, getVolunteerJks } from '../utils/MethodUtils.js';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
    name: "CollectionsReportPage",
    components: {
        NavigationBar,
        LoadingSpinner
    },
    setup() {
        const store = useStore();
        const router = useRouter();

        const startDate = ref();
        const endDate = ref();
        const regions = ref(store.getters.getMetadata.regions);
        const region = ref("-1");
        const jamatkhanas = ref(store.getters.getMetadata.jamatkhanas);
        const jamatkhana = ref("-1");
        const paymentType = ref("-1");
        const error = ref(null);
        const loadingCollectionReport = ref(false);
        const collectionReport = ref();
        const totalAmount = ref();
        const totalPayments = ref();

        // default start and end dates to today
        startDate.value = new Date().toLocaleDateString('en-CA');
        endDate.value = new Date().toLocaleDateString('en-CA');

        const filterJamatkhanas = (id) => {
            let returnJks = []
            for (let jk of jamatkhanas.value) {
                if (jk.regionId === id) {
                    returnJks.push(jk)
                }
            }
            return returnJks
        }

        const disableRunReport = computed(() => {
            return !(
                startDate.value && 
                endDate.value && 
                region.value != "-1" &&
                paymentType.value != "-1"
            );
        })

        const parsePaymentInfo = (paymentInfo) => {
            const paymentInfoJson = JSON.parse(paymentInfo);
            const {
                checkNumber,
                moneyOrderNumber
            } = paymentInfoJson
            if (checkNumber) {
                return checkNumber
            } else if (moneyOrderNumber) {
                return moneyOrderNumber
            }
        }

        const getPaymentMethod = (id) => {
            const methods = store.getters.getMetadata.paymentMethods;
            const filteredMethod = methods.filter(method => method.id === id);
            if (filteredMethod.length === 1) {
                return filteredMethod[0].name
            } else {
                return id;
            }
        }

        const getJamatkhanaName = (id) => {
            const methods = store.getters.getMetadata.jamatkhanas;
            const filteredMethod = methods.filter(method => method.id === id);
            if (filteredMethod.length === 1) {
                return filteredMethod[0].name
            } else {
                return id;
            }
        }

        const clickViewDetails = async (paymentID) => {
            let routeData = router.resolve({name: 'PaymentStatus', params: {paymentId: paymentID}});
            window.open(routeData.href, '_blank');
        }

        const printReport = () => {
            window.print();
        }

        const runReport = async () => {
            error.value = null;
            if (startDate.value > endDate.value) {
                error.value = "Start date cannot be later than end date";
                return;
            }
            loadingCollectionReport.value = true;

            // api on the other end is automatically doing the conversion for utc. 
            // start date works fine since it puts the time at local midnight
            // for this to be inclusive we just need to add +1 day to end date. 
            // this makes time be midnight of end date + 1.
            let inputEndDate = new Date((endDate.value + 'T00:00'));
            let newEndDate = new Date(inputEndDate.setDate(inputEndDate.getDate()+1)).toLocaleDateString('en-CA');
            let params = {
                startDate: startDate.value,
                endDate: newEndDate,
                paymentReceiveTypeId: paymentType.value
            }
            if (jamatkhana.value != "-1") {
                params.jamatkhanaId = jamatkhana.value;
            } else {
                params.regionId = region.value;
            }
            try {
                const response = await axios.get('/api/v1/admin/reports/collection', { params });
                const { success, statusMessage } = response.data;
                if (success) {
                    const { data } = response.data;
                    collectionReport.value = data;

                    let payments = 0;
                    let grandTotal = 0;
                    data.forEach(jk => {
                        payments += jk.numberOfPayments;
                        grandTotal += jk.totalAmountCollected;
                    })
                    totalAmount.value = grandTotal;
                    totalPayments.value = payments;
                } else {
                    console.log(statusMessage);
                    error.value=statusMessage;
                }
            } catch (err) {
                console.log(err);
                error.value = err
            }
            loadingCollectionReport.value = false;
        }

        return {
            displayInUSD,
            getDatefromUtcTimeStamp,
            printReport,
            clickViewDetails,
            parsePaymentInfo,
            getJamatkhanaName,
            getPaymentMethod,
            loadingCollectionReport,
            disableRunReport,
            filterJamatkhanas,
            collectionReport,
            runReport,
            startDate,
            endDate,
            regions,
            region,
            jamatkhanas,
            jamatkhana,
            paymentType,
            error,
            totalAmount,
            totalPayments
        }

    },
    async created() {
        let volunteer = this.$store.getters.getVolunteerInfo;
        this.regions = volunteer.regions;
        if(!this.regions) // we only have jks
        {
            let volJkInfo = getVolunteerJks();
            this.jamatkhanas = volJkInfo.jamatkhanas;
            this.jamatkhana = volJkInfo.selectedJamatkhana;
            this.region = this.jamatkhanas[0].regionId;        
        }
        if (!volunteer.resources.some(permission => permission.id === 3)) {
            this.$router.push("/search");
        }
    }
}
</script>

<style scoped>
.searchInputs {
    padding-bottom: 20px;
}
.searchInputRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}
.searchInputRow:nth-last-child(2) {
    padding-bottom: 10px;
}
.searchInputItem {
    padding-right: 20px;
    min-width: 200px;
}
.reportOptions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.link {
    color: #0d6efd;
    text-decoration: underline;
}
.link:hover {
    cursor: pointer;
}
</style>
