import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate'

const store = createStore({
    plugins: [createPersistedState(
      {storage: window.sessionStorage}
    )],
    state() {
      return {
        subject: null,
        volunteerInfo: null,
        householdDetails: null,
        members: null,
        metadata: null,
        isInVolunteerFlow: null,
        selectedMemberId: null,
        ACHPaymentDetails: null
      };
    },
  
    mutations: {
    //   loadIIPlatformSubjectFromLocalStorage(state) {
    //     console.log("loadIIPlatformSubjectFromLocalStorage");
    //     const subject = localStorage.getItem("IIPLATFORM.SUBJECT");
  
    //     if (subject != null) {
    //       console.log("loadIIPlatformSubjectFromLocalStorage, loaded:", subject);
    //       state.iiplatformSubject = subject;
    //     }
    //   },
  
      setSubject(state, subject) {
        // console.log("setIIPlatformSubject", subject);
        // localStorage.setItem("IIPLATFORM.SUBJECT", subject);
        state.subject = subject;
      },
      setVolunteerInfo(state, volunteerInfo) {
        state.volunteerInfo = volunteerInfo;
      },
      setHouseholdDetails(state, householdDetails) {
        state.householdDetails = householdDetails;
      },
      setMembers(state, members) {
        state.members = members;
      },
      setMetadata(state, metadata) {
        state.metadata = metadata;
      },
      setIsInVolunteerFlow(state, value) {
        state.isInVolunteerFlow = value;
      },
      setSelectedMemberId(state, value) {
        state.selectedMemberId = value;
      },
      setACHPaymentDetails(state, value) {
        state.ACHPaymentDetails = value;
      },
      clearAllInfo(state)
      {
        state.subject = null;
        state.volunteerInfo = null;
        state.householdDetails = null;
        state.members = null;
        state.metadata = null;
        state.isInVolunteerFlow = null;
        state.selectedMemberId = null;
        state.ACHPaymentDetails = null;
      }
  
    //   clearIIPlatformSubject(state) {
    //     console.log("clearIIPlatformSubject");
    //     localStorage.removeItem("IIPLATFORM.SUBJECT");
    //     state.iiplatformSubject = null;
    //   }
  
    },
    getters: {
      getSubject(state) {
        // console.log("getIIPlatformSubject", state);
        return state.subject;
      },
      getVolunteerInfo(state) {
        return state.volunteerInfo
      },
      getHouseholdDetails(state) {
        return state.householdDetails;
      },
      getMembers(state) {
        return state.members;
      },
      getMetadata(state) {
        return state.metadata;
      },
      getIsInVolunteerFlow(state) {
        return state.isInVolunteerFlow;
      },
      getSelectedMemberId(state) {
        return state.selectedMemberId;
      },
      getACHPaymentDetails(state) {
        return state.ACHPaymentDetails;
      },
    }
});

export default store;