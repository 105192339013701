<template>
<div>
    <NavigationBar />
    <div class="container" style="padding-top: 20px;">
        <div class="alert alert-success alert-dismissible d-flex align-items-center fade show" v-if="successMessage">
            <i class="bi-check-circle-fill"></i>
            <strong class="mx-2">Success!</strong> {{ successMessage }}
        </div>
        <div class="alert alert-danger alert-dismissible d-flex align-items-center fade show" v-if="error">
            <i class="bi-exclamation-octagon-fill"></i>
            <strong class="mx-2">Error!</strong> {{ error }}
            <br />
        </div>
        <h2>Processing Payments Report</h2>
        <div class="searchInputs">
            <div class="searchInputRow">
                <div class="searchInputItem" v-if="ownershipTypes">
                    <label for="ownershipType" class="col-form-label"><b>Ownership Type</b></label>
                    <select class="form-select" id="ownershipType" v-model="ownershipType">
                        <option value="-1" selected>All</option>
                        <option v-for="ownershipType in ownershipTypes" :value="ownershipType.id" :key="ownershipType.id">{{ownershipType.name}}</option>
                    </select>
                </div>
            </div>
            <div class="searchInputRow">
                <div class="searchInputItem">
                    <label for="startDate" class="col-form-label"><b>Start Date</b></label>
                    <input type="date" class="form-control" id="startDate" v-model="startDate" required>
                </div>
                <div class="searchInputItem">
                    <label for="endDate" class="col-form-label"><b>End Date</b></label>
                    <input type="date" class="form-control" id="endDate" v-model="endDate" required>
                </div>
            </div>
            <div class="searchInputRow">
                <div class="searchInputItem">
                    <button class="col-auto btn btn-success" :disabled="disableRunReport" @click="runReport">Run Report</button>
                </div>
            </div>
        </div>
        <div v-if="loadingReport">
            <LoadingSpinner />
        </div>
        <div v-if="report.length > 0 && !loadingReport" class="tableResults">
            <div class="actionButtonRow" v-if="this.volunteerInfo.resources.some(permission => permission.id === 9)">
                <div class="buttonGroup">
                    <button class="col-auto btn btn-secondary" @click="selectAll">Select All</button>
                    <button class="col-auto btn btn-secondary" @click="deselectAll">Deselect All</button>
                </div>
                <div class="markAsPaidGroup">
                    <button class="col-auto btn btn-primary" @click="markAsPaid">Mark As Paid</button>
                </div>
            </div>
            <table class="table">
                <tbody>
                    <tr>
                        <td></td>
                        <td><b>Payment ID</b></td>
                        <td><b>Amount</b></td>
                        <td><b>Initiated On</b></td>
                        <td><b>Initiated By</b></td>
                        <td><b>Member ID</b></td>
                        <td><b>Coverage Location</b></td>
                    </tr>
                    <tr v-for="payment in report" :key="payment.id">
                        <td><input class="form-check-input" type="checkbox" value="" v-model="payment.isSelected"></td>
                        <td><div class="link" @click="clickViewDetails(payment.id)">{{payment.id}}</div></td>
                        <td>{{displayInUSD(payment.amount)}}</td>
                        <td>{{getLocalTimestampfromUtcTimeStamp(payment.paymentInitiatedAt)}}</td>
                        <td>{{payment.initiatedBy.name}}</td>
                        <td>{{payment.initiatedBy.id}}</td>
                        <td>{{getJamatkhanaName(payment.locationId)}}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td><b>Total:</b></td>
                        <td><b>{{displayInUSD(totalAmount)}}</b></td>
                        <td></td>
                        <td><b>Count of Selected:</b></td>
                        <td>{{totalSelected}}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>    
    </div>
</div>

</template>

<script>
import axios from 'axios';
import NavigationBar from '../components/NavigationBar.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import { displayInUSD, getLocalTimestampfromUtcTimeStamp, getVolunteerJks } from '../utils/MethodUtils.js';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
    name: "ProcessingPaymentsReportPage",
    components: {
        NavigationBar,
        LoadingSpinner
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const ownershipTypes = ref([]);
        const ownershipType = ref("-1");
        const startDate = ref();
        const endDate = ref();
        const report = ref([]);

        const error = ref(null);
        const successMessage = ref(null);
        const loadingReport = ref(false);

        // default start and end dates to today
        startDate.value = new Date().toLocaleDateString('en-CA');
        endDate.value = new Date().toLocaleDateString('en-CA');

        const disableRunReport = computed(() => {
            return !(startDate.value && endDate.value)
        })

        const getJamatkhanaName = (id) => {
            const methods = store.getters.getMetadata.jamatkhanas;
            const filteredMethod = methods.filter(method => method.id === id);
            if (filteredMethod.length === 1) {
                return filteredMethod[0].name
            } else {
                return id;
            }
        }

        const selectAll = () => {
            const updatedReport = report.value.map(payment => {
                payment.isSelected = true;
                return payment;
            })

            report.value = updatedReport;
        }

        const deselectAll = () => {
            const updatedReport = report.value.map(payment => {
                payment.isSelected = false;
                return payment;
            })

            report.value = updatedReport;
        }

        const totalAmount = computed(() => {
            let total = 0;
            report.value.forEach((payment) => {
                if (payment.isSelected) {
                    total += payment.amount
                }
            })
            return total
        })

        const totalSelected = computed(() => {
            let total = 0;
            report.value.forEach((payment) => {
                if (payment.isSelected) {
                    total += 1
                }
            })
            return total
        })

        const clickViewDetails = async (paymentID) => {
            let routeData = router.resolve({name: 'PaymentStatus', params: {paymentId: paymentID}});
            window.open(routeData.href, '_blank');
        }

        const markAsPaid = async () => {
            loadingReport.value = true;
            let selectedPaymentIds = [];
            report.value.forEach((payment) => {
                if (payment.isSelected) {
                    selectedPaymentIds.push(payment.id)
                }
            })
            let payload = {
                paymentIds: selectedPaymentIds,
                count: totalSelected.value,
                totalAmount: totalAmount.value
            }
            try {
                const response = await axios.post(`/api/v1/burial/batch`, payload);
                const { success, statusMessage } = response.data;
                if (success) {
                    const { data } = response.data;
                    successMessage.value = statusMessage;
                    if (data.emailErrors && data.emailErrors.length > 0) {
                        error.value = "There were errors while sending out all emails. Check console for more details";
                        console.log(emailErrors)
                    }
                    runReport(true);
                } else {
                    console.log(statusMessage)
                }
            } catch (err) {
                console.error(err);
            }
            loadingReport.value = false;
        }

        const runReport = async (disableErr) => {
            error.value = null;
            if (startDate.value > endDate.value) {
                error.value = "Start date cannot be later than end date";
                return;
            }
            loadingReport.value = true;

            // api on the other end is automatically doing the conversion for utc. 
            // start date works fine since it puts the time at local midnight
            // for this to be inclusive we just need to add +1 day to end date. 
            // this makes time be midnight of end date + 1.
            let inputEndDate = new Date((endDate.value + 'T00:00'));
            let newEndDate = new Date(inputEndDate.setDate(inputEndDate.getDate()+1)).toLocaleDateString('en-CA');
            let params = {
                startDate: startDate.value,
                endDate: newEndDate,
                ownershipTypeId: ownershipType.value
            }

            try {
                const response = await axios.get('/api/v1/admin/reports/processingpayments', { params });
                const { success, statusMessage } = response.data;
                if (success) {
                    const { data } = response.data;
                    const updatedReport = data.map(payment => {
                        payment.isSelected = false;
                        return payment
                    })
                    report.value = updatedReport;
                    if (report.value.length === 0 && !disableErr) {
                        error.value = "No payments in processing state from the search criteria."
                    }
                } else {
                    console.log(statusMessage);
                    error.value=statusMessage;
                }
            } catch (err) {
                console.log(err);
                error.value = err
            }

            loadingReport.value = false;
        }

        const volunteerInfo = store.getters.getVolunteerInfo;

        return {
            displayInUSD,
            getLocalTimestampfromUtcTimeStamp,
            getJamatkhanaName,
            loadingReport,
            disableRunReport,
            clickViewDetails,
            selectAll,
            deselectAll,
            markAsPaid,
            runReport,
            error,
            successMessage,
            ownershipType,
            ownershipTypes,
            startDate,
            endDate,
            report,
            totalAmount,
            totalSelected,
            volunteerInfo
        }

    },
    async created() {
        const metaData = this.$store.getters.getMetadata
        this.ownershipTypes = metaData.bankAccountOwnershipTypes
        let volunteer = this.$store.getters.getVolunteerInfo;
        if (!volunteer.resources.some(permission => permission.id === 8)) {
            this.$router.push("/search");
        }
    }
}
</script>

<style scoped>
.searchInputs {
    padding-bottom: 20px;
}
.searchInputRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}
.searchInputRow:nth-last-child(2) {
    padding-bottom: 10px;
}
.searchInputItem {
    padding-right: 20px;
    min-width: 200px;
}
.link {
    color: #0d6efd;
    text-decoration: underline;
}
.link:hover {
    cursor: pointer;
}
.tableResults {
    padding-top: 20px;
}
button {
    margin-right: 10px;
}
.actionButtonRow {
    display: flex;
    flex-direction: row;
}
.buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1;
}
.markAsPaidGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;
}
</style>
