<template>
<div>
    <NavigationBar />
    <div class="container-fluid py-4">
        <div class="card px-4" style="width: 100%;">
            <div class="card-header bg-primary text-white bg-gradient" v-if="!checkSearching">
                <h3><i class="bi bi-search"></i> Member Search</h3>
            </div>
            <hr>
            <div v-if="!searching && !checkSearching">
                <!-- Member Search Form -->
                <form @submit.prevent="search" @keydown="form.onKeydown($event)" class="form-horizontal">
                    <!-- Error msg if no search fields provided -->
                    <div v-if="msg.text" class="row mx-1">
                        <div class="alert" :class="msg.text && msg.type">
                            {{ msg.text }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="jamatkhana" class="col-sm-2 col-form-label">
                            <h5>Jamatkhana:</h5>
                        </label>
                        <div class="col-sm-10">
                            <select class="form-select dropdown-input" id="jamatkhana" v-model="selectedJamatkhana">
                                <option :value="placeholderValue">Select an option (Required)</option>
                                <option v-for="jk in jamatkhanas" :key="jk.id" :value="jk.id">{{jk.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="phoneSearch" class="col-sm-2 col-form-label">
                            <h5>Phone:</h5>
                        </label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="phoneSearch" :class="{'is-invalid':form.errors.has('phone')}" v-model="form.phone">
                            <HasError :form="form" field="phone" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="emailSearch" class="col-sm-2 col-form-label">
                            <h5>Email:</h5>
                        </label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="emailSearch" :class="{'is-invalid':form.errors.has('email')}" v-model="form.email">
                            <HasError :form="form" field="email" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="firstnameSearch" class="col-sm-2 col-form-label">
                            <h5>First Name:</h5>
                        </label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="firstnameSearch" :class="{'is-invalid':form.errors.has('firstname')}" v-model="form.firstname">
                            <HasError :form="form" field="firstname" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="lastnameSearch" class="col-sm-2 col-form-label">
                            <h5>Last Name:</h5>
                        </label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="lastnameSearch" :class="{'is-invalid':form.errors.has('lastname')}" v-model="form.lastname">
                            <HasError :form="form" field="lastname" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="idSearch" class="col-sm-2 col-form-label">
                            <h5>Member Id:</h5>
                        </label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="idSearch" :class="{'is-invalid':form.errors.has('id')}" v-model="form.id">
                            <HasError :form="form" field="id" />
                        </div>
                    </div>
                    <Button :form="form" class="btn btn-primary mt-3">Search Member</Button>
                </form>
                <br>
                <br>
                <div class="card-header bg-primary text-white bg-gradient">
                    <h3><i class="bi bi-wallet2"></i> Payment Search</h3>
                </div>
                <hr>
                <form @submit.prevent="paymentSearch" @keydown="form.onKeydown($event)" class="form-horizontal">
                    <div class="form-group row">
                        <label for="paymentIdSearch" class="col-sm-2 col-form-label">
                            <h5>Payment Id:</h5>
                        </label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="paymentIdSearch" :class="{'is-invalid':form.errors.has('paymentId')}" v-model="paymentId">
                            <HasError :form="form" field="paymentId" />
                        </div>
                    </div>
                    <Button :form="form" class="btn btn-primary mt-3">Search Payment</Button>
                </form>
                <br>
                <br>
                <div class="card-header bg-primary text-white bg-gradient">
                    <h3><i class="bi bi-credit-card-2-front"></i> Check Number Search</h3>
                </div>
                <hr>
                <form @submit.prevent="searchCheckNumber" @keydown="form.onKeydown($event)" class="form-horizontal">
                    <!-- Error msg if no search fields provided -->
                    <div v-if="checkMsg.text" class="row mx-1">
                        <div class="alert" :class="checkMsg.text && checkMsg.type">
                            {{ checkMsg.text }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="checkNumberSearch" class="col-sm-2 col-form-label">
                            <h5>Check Number:</h5>
                        </label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="checkNumberSearch" :class="{'is-invalid':form.errors.has('checkNumber')}" v-model="checkNumber">
                            <HasError :form="form" field="checkNumber" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="jamatkhana" class="col-sm-2 col-form-label">
                            <h5>Jamatkhana:</h5>
                        </label>
                        <div class="col-sm-10">
                            <select class="form-select dropdown-input" id="jamatkhanaForCheck" v-model="selectedJamatkhanaForCheck">
                                <option :value="placeholderValue">Select an option (Required)</option>
                                <option v-for="jk in jamatkhanasForCheck" :key="jk.id" :value="jk.id">{{jk.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="year" class="col-sm-2 col-form-label">
                            <h5>Coverage Year:</h5>
                        </label>
                        <div class="col-sm-10">
                            <select class="form-select dropdown-input" id="year" v-model="selectedYear">
                                <option :value="placeholderValue">Select an option (Required)</option>
                                <option v-for="year in years" :key="year" :value="year">{{year}}</option>
                            </select>
                        </div>
                    </div>
                    <Button :form="form" class="btn btn-primary mt-3">Search Check Number</Button>
                </form>
                <br>
            </div>
            <!-- Search Results -->
            <div v-if="searching && !error">
                <div v-if="loading">
                    <LoadingSpinner />
                    <!-- Replace with spinner once available -->
                </div>
                <div v-else>
                    <!-- <h3>Location: {{ currentLocation?.name }}</h3> -->
                    <!-- if data is null then display this -->
                    <div v-if="memData.data">Search Location: <b> {{getSearchScope(memData.searchLocation.jamatkhanaId, memData.searchLocation.regionId)}} </b></div>
                    <div v-if="memData.data && memData.data.length == 0">
                        <h3>No members found</h3>
                    </div>
                    <!-- if data then display data. Based on viewport width we display table or card-->
                    <div v-else>
                        Showing {{firstRecord}}-{{lastRecord}} of {{totalRecords}} records
                        <hr>
                        <div v-if="cardOrTable">
                            <div class="container searchCard" v-for="member in memData.data" :key="member.memberId" @click.right.prevent @keydown="keydown" @copy.prevent @paste.prevent @click="goToVerify(member.memberId)">
                                <div class="row">
                                    <div class="col">{{ member.memberId }} </div>
                                    <div class="col">
                                        <div class="row d-flex float-end me-1">{{member.firstName + " " + member.lastName }}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">{{ (member.phone && member.phone.length > 0) ? member.phone[0].number : ""}}</div>
                                    <div class="col">
                                        <div class="row d-flex float-end me-1">{{member.email}}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col"></div>
                                    <div class="col">
                                        <div class="row d-flex float-end me-1">{{member.jamatkhana}}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">Last Reg Date: {{ member.registrationDate ? getFormattedDate(member.registrationDate) : "-" }}</div>
                                    <div class="col">Last Reg Loc: {{ (member.registrationLocation && member.registrationDate) ? member.registrationLocation : "-" }}</div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Member Id</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Phone Number</th>
                                        <th>Email</th>
                                        <th>Jamatkhana</th>
                                        <th>Last Registration Date</th>
                                        <th>Last Registration Location</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="table-row" v-for="member in memData.data" :key="member.memberId" @click.right.prevent @keydown="keydown" @copy.prevent @paste.prevent @click="goToVerify(member.memberId)">
                                        <td> {{member.memberId }}</td>
                                        <td> {{member.firstName }}</td>
                                        <td> {{ member.lastName }}</td>
                                        <td v-if="member.phone && member.phone.length > 0">
                                            <div v-for="p in member.phone" :key="p.id">
                                                {{p.name + ": " + p.number}}
                                            </div>
                                        </td>
                                        <td v-else></td>
                                        <td> {{member.email}}</td>
                                        <td> {{ member.jamatkhana }}</td>
                                        <td> {{ member.registrationDate ? getFormattedDate(member.registrationDate) : "-" }}</td>
                                        <td> {{ (member.registrationLocation && member.registrationDate) ? member.registrationLocation : "-" }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col d-flex justify-content-center">
                            <PagerComponent :currentPageNo="currentPageNo" :lastPageNo="lastPageNo" @first-page="search(1)" @prev-page="search(currentPageNo - 1);" @next-page="search(currentPageNo + 1)" @last-page="search(lastPageNo)" :key="pageKey"></PagerComponent>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col">
                            <button class="btn btn-primary" @click="backSearch">Back to Search</button>
                        </div>
                        <div v-if="parentLocation.name" class="col d-flex justify-content-end">
                            <button class="btn btn-primary float-right" @click="parentLocationSearch">Expand Search to {{parentLocation.name}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Search Results -->
            <div v-if="checkSearching && !error">
                <div v-if="checkLoading">
                    <LoadingSpinner />
                </div>
                <div v-else>
                    <!-- if data is null then display this -->
                    <div v-if="checkData.data && checkData.data.length == 0">
                        <h3>No payments found</h3>
                    </div>
                    <!-- if data then display data. Based on viewport width we display table or card-->
                    <div v-else>
                        <div class="card-header bg-primary text-white bg-gradient">
                            <h3><i class="bi bi-credit-card-2-front"></i> Payment Search</h3>
                        </div>
                        <hr>
                        Showing {{checkData.data.length}} records
                        <hr>
                        <div v-if="cardOrTable">
                            <div class="container searchCard" v-for="payment in checkData.data" :key="payment.payment.id" @click.right.prevent @keydown="keydown" @copy.prevent @paste.prevent @click="goToPaymentDetails(payment.payment.id)">
                                <div class="row">
                                    <div clas="col"><b>Payment ID: </b>{{ payment.payment.id }}</div>
                                </div>
                                <div class="row">
                                    <div clas="col"><b>Coverage Year: </b>{{ payment.payment.year }}</div>
                                </div>
                                <div class="row">
                                    <div clas="col"><b>Jamatkhana: </b>{{ payment.paymentLocation }}</div>
                                </div>
                                <div class="row">
                                    <div clas="col"><b>Covered Members: </b>{{ payment.coveredMembers.join(', ') }}</div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Payment ID</th>
                                        <th>Coverage Year</th>
                                        <th>Jamatkhana</th>
                                        <th>Covered Members</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="table-row" v-for="payment in checkData.data" :key="payment.payment.id" @click.right.prevent @keydown="keydown" @copy.prevent @paste.prevent @click="goToPaymentDetails(payment.payment.id)">
                                        <td> {{ payment.payment.id }}</td>
                                        <td> {{ payment.payment.year }}</td>
                                        <td> {{ payment.paymentLocation }}</td>
                                        <td> {{ payment.coveredMembers.join(', ') }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col">
                            <button class="btn btn-primary" @click="backToSearchFromCheckResults">Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import {
    ref
} from 'vue';
import Form from 'vform';
import moment from 'moment';
import {
    Button,
    HasError
} from 'vform/src/components/bootstrap5';
import {
    getSearchScope,
    getVolunteerJks,
    placeholderValue
} from '../utils/MethodUtils.js';
import NavigationBar from '../components/NavigationBar.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import PagerComponent from '../components/PagerComponent.vue';
export default {
    name: "SearchPage",
    components: {
        NavigationBar,
        LoadingSpinner,
        Button,
        HasError,
        PagerComponent
    },

    setup() {
        const loading = ref(false);
        const searching = ref(false);
        const checkLoading = ref(false);
        const checkSearching = ref(false);
        const memData = ref([]);
        const checkData = ref([]);
        const msg = ref({});
        const checkMsg = ref({});
        const error = ref(false);
        const permissions = ref();
        const cardOrTable = ref(false);
        const pageKey = ref([-1, -1]);
        const currentLocation = ref({
            id: "",
            name: ""
        });
        const parentLocation = ref({
            id: "",
            name: "Region"
        });
        const paymentId = ref("");
        const checkNumber = ref("");
        const jkOptions = getVolunteerJks();
        const jamatkhanas = ref(jkOptions.jamatkhanas);
        const selectedJamatkhana = ref(jkOptions.selectedJamatkhana);
        const jkOptionsForCheck = getVolunteerJks();
        const jamatkhanasForCheck = ref(jkOptionsForCheck.jamatkhanas);
        const selectedJamatkhanaForCheck = ref(jkOptionsForCheck.selectedJamatkhana);
        // hardcoded retreive value from API when we store the year in future releases
        const years = ref(["2022", "2023", "2024"])
        const selectedYear = ref("N/A")

        return {
            getSearchScope,
            loading,
            searching,
            checkLoading,
            checkSearching,
            memData,
            checkData,
            error,
            permissions,
            cardOrTable,
            pageKey,
            msg,
            checkMsg,
            currentLocation,
            parentLocation,
            paymentId,
            checkNumber,
            jamatkhanas,
            selectedJamatkhana,
            jamatkhanasForCheck,
            selectedJamatkhanaForCheck,
            placeholderValue,
            years,
            selectedYear,
        }
    },
    data: () => ({
        form: new Form({
            phone: "",
            email: "",
            firstname: "",
            lastname: "",
            id: "",
            locId: "",
            limitBy: "jamatkhana"
        }),
        window: {
            width: 0
        }
    }),
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.permissions = this.$store.getters.getVolunteerInfo;
        if (!this.permissions.resources.some(p => p.id === 1)) {
            this.$store.commit('clearAllInfo');
            axios.get("/api/auth/logout");
            this.$router.push("/login");
        }
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        // payment search
        async paymentSearch() {
            if (this.paymentId == "") {
                this.msg.type = "alert-danger"
                this.msg.text = "Payment Id is required."
                return
            }
            this.$router.push({
                name: 'PaymentStatus',
                params: {
                    paymentId: this.paymentId
                }
            })
        },
        // search method to retrieve data
        async search(pageNumber = 1) {
            this.$store.commit('setSelectedMemberId', null);
            if (!this.selectedJamatkhana || this.selectedJamatkhana == placeholderValue) {
                this.msg.type = "alert-danger";
                this.msg.text = "Please select a Jamatkhana";
                return;
            }
            if (this.form.phone && this.form.phone != "") // we have a phone
            {
                let tmpPhone = this.form.phone.replace(/\D/g, "");
                if (tmpPhone.length != 10) {
                    this.msg.type = "alert-danger";
                    this.msg.text = "Please provide a valid 10 digit phone number";
                    return;
                }
            }
            if (!(this.form.phone != "" || this.form.email != "" || (this.form.firstname != "" && this.form.lastname != "") || this.form.id != "")) {
                this.msg.type = "alert-danger"
                this.msg.text = "At least one field is required!"
                if ((this.form.firstname == "" && this.form.lastname != "") || (this.form.firstname != "" && this.form.lastname == "")) {
                    this.msg.text = "Both First and Last names are required"
                }
                return
            } else {
                this.searching = true
                this.loading = true
                const hasName = (this.form.firstname && this.form.lastname)
                try {
                    this.error = false
                    let params = {
                        memberId: this.form.id ? this.form.id : null,
                        firstName: hasName ? this.form.firstname : null,
                        lastName: hasName ? this.form.lastname : null,
                        phone: this.form.phone ? this.form.phone.replace(/\D/g, "") : null,
                        email: this.form.email ? this.form.email : null,
                        limitBy: this.form.limitBy,
                        page: pageNumber > 0 ? pageNumber : 1
                    }
                    if (this.form.limitBy === "jamatkhana") {
                        params.jamatkhanaId = this.selectedJamatkhana;
                    }
                    let {
                        data
                    } = await axios.get("/api/v1/iiplatform/search", {
                        params
                    });
                    if (data != null) {
                        if (!data.success) {
                            this.msg.type = "alert-danger"
                            this.msg.text = data.statusMessage
                            this.error = true
                            this.searching = false
                        } else {
                            this.memData = data
                            this.pageKey = [data.paginatorInfo.currentPage, data.paginatorInfo.lastPage];
                        }
                    }
                } catch (err) {
                    this.msg.type = "alert-danger"
                    this.msg.text = err;
                    this.error = true
                    this.searching = false
                }
                this.loading = false;
            }
        },
        async searchCheckNumber() {
            if (!this.selectedJamatkhanaForCheck || this.selectedJamatkhanaForCheck == placeholderValue) {
                this.checkMsg.type = "alert-danger";
                this.checkMsg.text = "Please select a Jamatkhana";
                return;
            }
            if (!this.checkNumber) {
                this.checkMsg.type = "alert-danger";
                this.checkMsg.text = "Please enter a check number";
                return;
            }
            if (!this.selectedYear || this.selectedYear == placeholderValue) {
                this.checkMsg.type = "alert-danger";
                this.checkMsg.text = "Please select a coverage year";
                return;
            }
            this.checkSearching = true
            this.checkLoading = true
            try {
                this.error = false
                let params = {
                    checkNumber: this.checkNumber,
                    year: this.selectedYear ? this.selectedYear : null,
                    jamatkhanaId: this.selectedJamatkhanaForCheck
                }
                let {
                    data
                } = await axios.get("/api/v1/burial/check", {
                    params
                });
                if (data != null) {
                    if (!data.success) {
                        this.checkMsg.type = "alert-danger"
                        this.checkMsg.text = data.statusMessage
                        this.error = true
                        this.checkSearching = false
                    } else {
                        this.checkData = data
                    }
                }
            } catch (err) {
                this.checkMsg.type = "alert-danger"
                this.checkMsg.text = err;
                this.error = true
                this.checkSearching = false
            }
            this.checkLoading = false;
        },
        backSearch() {
            this.msg.text = null
            this.form.limitBy = "jamatkhana"
            this.parentLocation.name = "Region"
            this.searching = false
        },
        parentLocationSearch() {
            if (this.form.limitBy == "region") {
                this.form.limitBy = null
                this.parentLocation.name = ""
            } else if (this.form.limitBy == "jamatkhana") {
                this.form.limitBy = "region"
                this.parentLocation.name = "National"

            }
            this.search()
        },
        handleResize() {
            this.window.width = window.innerWidth;
            if (this.window.width < 550) {
                this.cardOrTable = true
            } else {
                this.cardOrTable = false
            }
        },
        goToVerify(id) {
            this.$store.commit('setSelectedMemberId', id);
            this.$router.push("/userInfo");
        },
        getFormattedDate(date) {
            return moment(date).format("LL")
        },
        goToPaymentDetails(id) {
            this.$router.push({
                name: 'PaymentStatus',
                params: {
                    paymentId: id
                }
            })
        },
        backToSearchFromCheckResults() {
            this.checkSearching = false
        },
    },
    computed: {
        currentPageNo: function () {
            return this.memData.paginatorInfo.currentPage
        },
        lastPageNo: function () {
            return this.memData.paginatorInfo.lastPage
        },
        firstRecord: function () {
            return this.memData.paginatorInfo.firstItem
        },
        lastRecord: function () {
            return this.memData.paginatorInfo.lastItem
        },
        totalRecords: function () {
            return this.memData.paginatorInfo.total
        },
    }
}
</script>

<style scoped>
.btn {
    font-weight: bold;
    color: white;
}

input.field {
    width: 50%
}

.searchCard {
    background-color: #ececec;
    margin: 10px 0px;
}

.table-row:hover,
.searchCard:hover {
    cursor: pointer;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
}
</style>
