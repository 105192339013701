<template>
  <nav class="navbar navbar-expand-lg navbar-light" :class="{navbarVolunteer: isVolunteer}">
    <div class="container">
      <router-link class="navbar-brand" :class="{brandVolunteer: isVolunteer}" to="/">Funeral Expense Program</router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" 
        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
        aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto"></ul>
        <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" :class="{brandVolunteer: isVolunteer}" href="#" v-on:click="homeClicked">Home</a>
          </li>
          <li v-if="isVolunteer" class="nav-item">
            <a class="nav-link" :class="{brandVolunteer: isVolunteer}" href="#" v-on:click="portalClicked">Volunteer Portal</a>
          </li>
          <li v-if="showPayments" class="nav-item">
            <a class="nav-link" href="#" v-on:click="paymentsClicked">Payments</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{brandVolunteer: isVolunteer}" href="#" v-on:click="logoutClicked">Logout</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
  import axios from 'axios';
  export default {
    name: "NavigationBar",
    methods: {
      async logoutClicked() {
        this.$store.commit('clearAllInfo');
        const response = await axios.get("/api/auth/logout");
        console.log(response);
        this.$router.push("/login");
      },
      paymentsClicked() {
        this.$router.push("/payments");
      },
      homeClicked() {
        this.$router.push("/");
      },
      portalClicked() {
        let volunteer = this.$store.getters.getVolunteerInfo;
        if (volunteer.resources.some(permission => permission.id === 1)) {
          this.$router.push("/portal");
        }
      }
    },
    data() {
      const isInVolunteerMode = this.$store.getters.getIsInVolunteerFlow;
      return {
        isVolunteer: isInVolunteerMode,
        showPayments: isInVolunteerMode === false
      }
    }
  }
</script>
<style scoped>
  .navbar {
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  .navbarVolunteer {
    background-color: rgba(25,162,157);
  }
  .brandVolunteer {
    color: rgba(255,255,255);
  }
  .brandVolunteer:hover {
    color: rgba(255,255,255);
  }
</style>