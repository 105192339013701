<template>
<div>
    <NavigationBar />
    <div class="container-fluid px-4">
        <h2></h2>
        <div v-if="loaded">
            <div class="address-card card">
                <h4 class="px-2 py-2 card-header bg-primary text-white bg-gradient" style="width: 100%;"><i class="bi bi-house-fill"></i> Address & Household Details</h4>
                <hr class="smaller-hr">
                <div class="card-info px-3 pb-1">
                    <span>
                        Address: {{familyInfo.address.line1}}
                    </span>
                    <br>
                    <span>
                        Apt/Suite: {{familyInfo.address.line2}}
                    </span>
                    <br>
                    <span>
                        City: {{familyInfo.address.city}}
                    </span>
                    <br>
                    <span>
                        State: {{familyInfo.address.state.name}}
                    </span>
                    <br>
                    <span>
                        Zipcode: {{familyInfo.address.zipCode}}
                    </span>
                    <br><br>
                    <span>
                        Region: {{familyInfo.region.name}}
                    </span>
                    <span>
                        <h6>Jamatkhana: {{familyInfo.jamatkhana.name}}</h6>
                    </span>
                    <br>
                    <div class="row">
                        <div class="modal" id="editAddressModal" tabindex="-1" aria-labelledby="editAddressModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="editAddressModalLabel">Edit Address</h5>
                                        <button type="button" class="btn-close" @click="closeModalAfterValidation('editAddressModal')" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <form class="form-horizontal was-validated" @submit.prevent="editAddressInfo" action="editAddressInfo">
                                            <div class="form-group row">
                                                <label for="addressEdit" class="col-sm-3 col-form-label">
                                                    <h6>Address:</h6>
                                                </label>
                                                <div class="">
                                                    <input type="text" class="form-control" id="addressEdit" v-model="formAddress.address.line1" required>
                                                    <div class="invalid-feedback">
                                                        Please enter your address.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="aptSuiteEdit" class="col-sm-3 col-form-label">
                                                    <h6>Apt/Suite:</h6>
                                                </label>
                                                <div class="">
                                                    <input type="text" class="form-control" id="aptSuiteEdit" v-model="formAddress.address.line2">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="cityEdit" class="col-sm-3 col-form-label">
                                                    <h6>City:</h6>
                                                </label>
                                                <div class="">
                                                    <input type="text" class="form-control" id="cityEdit" v-model="formAddress.address.city" required>
                                                    <div class="invalid-feedback">
                                                        Please enter a city.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="stateEdit" class="col-sm-3 col-form-label">
                                                    <h6>State:</h6>
                                                </label>
                                                <div class="">
                                                    <select class="form-select" id="stateEdit" v-model="formAddress.address.stateObj">
                                                        <option v-for="state in states" :value="state" :key="state.id" :selected="this.formAddress.address.stateObj" required>
                                                            {{state.name}}
                                                        </option>
                                                    </select>
                                                    <div class="invalid-feedback">
                                                        Please select a state.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="zipEdit" class="col-sm-3 col-form-label">
                                                    <h6>Zipcode:</h6>
                                                </label>
                                                <div class="">
                                                    <input type="text" class="form-control" id="zipEdit" v-model="formAddress.address.zipCode" required>
                                                    <div class="invalid-feedback">
                                                        Please enter a zip code.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="regionEdit" class="col-sm-3 col-form-label">
                                                    <h6>Region:</h6>
                                                </label>
                                                <div class="">
                                                    <select class="form-select" id="regionEdit" v-model="region" required>
                                                        <option v-for="region in regions" :key="region.id" :value="region" :selected="this.formAddress.region">{{region.name}}</option>
                                                    </select>
                                                    <div class="invalid-feedback">
                                                        Please select a region.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="jamatkhanaEdit" class="col-sm-3 col-form-label">
                                                    <h6>Jamatkhana:</h6>
                                                </label>
                                                <div class="">
                                                    <select class="form-select" id="jamatkhanaEdit" v-model="formAddress.jamatkhana" required>
                                                        <option v-for="jamatkhana in filterJamatkhanas(region.id)" :value="jamatkhana" :key="jamatkhana.id" :selected="this.formAddress.jamatkhana">{{jamatkhana.name}}</option>
                                                    </select>
                                                    <div class="invalid-feedback">
                                                        Please select a jamatkhana.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col mt-3">
                                                <Button class="btn btn-primary d-flex flex-row float-end" type="submit">Save</Button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <button class="btn btn-success" @click="clickViewPayments"><i class="bi bi-wallet2"></i> View Payments</button>
                        </div>
                        <div class="col">
                            <button class="btn btn-primary d-flex flex-row float-end" data-bs-toggle="modal" data-bs-target="#editAddressModal" @click="populateEditAddress">Edit Address</button>
                        </div>
                        <br /><br />
                    </div>
                </div>
            </div>
            <!-- Add Member Pop-up Modal -->
            <div class="modal" id="addMemberModal" tabindex="-1" aria-labelledby="addMemberModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="addMemberModalLabel">Add a Family Member</h5>
                            <button type="button" class="btn-close" @click="closeModalAfterValidation('addMemberModal')" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form ref="add_member_form" @submit.prevent="addMember" class="form-horizontal was-validated" action="addMember">
                                <div class="form-group row">
                                    <label for="firstNameAdd" class="col-form-label">
                                        <h6>First Name:</h6>
                                    </label>
                                    <div class="">
                                        <input type="text" class="form-control" id="firstNameAdd" v-model="formAddMember.firstName" required>
                                        <div class="invalid-feedback">
                                            Please enter your first name.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lastNameAdd" class="col-form-label">
                                        <h6>Last Name:</h6>
                                    </label>
                                    <div class="">
                                        <input type="text" class="form-control" id="lastNameAdd" v-model="formAddMember.lastName" required>
                                        <div class="invalid-feedback">
                                            Please enter your last name.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="relationshipAdd" class="col-form-label">
                                        <h6>Relationship to Head of Household:</h6>
                                    </label>
                                    <div class="">
                                        <select class="form-select" id="relationshipAdd" v-model="formAddMember.relationshipToHeadOfHousehold" required>
                                            <option value="-1" selected>Please select</option>
                                            <option v-for="relationship in relationships" :key="relationship.id" :value="relationship" :selected="this.formAddMember.relationshipToHeadOfHousehold">{{relationship.name}}</option>
                                        </select>
                                        <div class="invalid-feedback">
                                            Please select your relationship to the head of household
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="emailAdd" class="col-form-label">
                                        <h6>Email:</h6>
                                    </label>
                                    <div class="">
                                        <input type="email" class="form-control" id="emailAdd" v-model="formAddMember.contactInfo.email">
                                        <div class="invalid-feedback">
                                            Please enter your email.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="phoneTypeAdd" class="col-form-label">
                                        <h6>Phone Type:</h6>
                                    </label>
                                    <div class="">
                                        <select v-if="formAddMember.contactInfo.phone.number && formAddMember.contactInfo.phone.number.length > 0" class="form-select" id="phoneTypeAdd" v-model="formAddMember.contactInfo.phone.typeId" required>
                                            <option value="" selected>Please select</option>
                                            <option v-for="phoneType in phoneTypes" :key="phoneType.id" :value="phoneType.id">{{phoneType.name}}</option>
                                        </select>
                                        <select v-else class="form-select" id="phoneTypeAdd" v-model="formAddMember.contactInfo.phone.typeId">
                                            <option value="" selected>Please select</option>
                                            <option v-for="phoneType in phoneTypes" :key="phoneType.id" :value="phoneType.id">{{phoneType.name}}</option>
                                        </select>
                                        <div class="invalid-feedback">
                                            Please select a phone type.
                                        </div>
                                    </div>
                                    <label for="phoneAdd" class="col-form-label">
                                        <h6>Phone Number:</h6>
                                    </label>
                                    <div class="">
                                        <input type="text" pattern="^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$" class="form-control" id="phoneAdd" v-model="formAddMember.contactInfo.phone.number">
                                        <div class="invalid-feedback">
                                            Please enter a 10-digit phone number.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="dobAdd" class="col-form-label">
                                        <h6>Date of Birth:</h6>
                                    </label>
                                    <div class="">
                                        <input type="date" class="form-control" id="dobAdd" v-model="formAddMember.dateOfBirth" required>
                                        <div class="invalid-feedback">
                                            Please select your date of birth.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col mt-3">
                                    <button class="btn btn-primary d-flex flex-row float-end" type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Edit Member Pop-up Modal -->
            <div class="modal" id="editMemberModal" tabindex="-1" aria-labelledby="editMemberModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editMemberModalLabel">Edit a Family Member</h5>
                            <button type="button" class="btn-close" @click="closeModalAfterValidation('editMemberModal')" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form @submit.prevent="editMember" class="form-horizontal was-validated" action="editMember">
                                <div class="form-group row">
                                    <label for="firstnameEdit" class="col-form-label">
                                        <h6>First Name:</h6>
                                    </label>
                                    <div class="">
                                        <input type="text" class="form-control" id="firstnameEdit" v-model="formEditMember.firstName" required>
                                        <div class="invalid-feedback">
                                            Please enter your first name.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lastnameEdit" class="col-form-label">
                                        <h6>Last Name:</h6>
                                    </label>
                                    <div class="">
                                        <input type="text" class="form-control" id="lastnameEdit" v-model="formEditMember.lastName" required>
                                        <div class="invalid-feedback">
                                            Please enter your last name.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group-row">
                                    <label for="relationship" class="col-form-label">
                                        <h6>Relationship to Head Of Household:</h6>
                                    </label>
                                    <div class="">
                                        <select class="form-select" id="relationship" v-model="formEditMember.relationshipToHeadOfHousehold" required>
                                            <option v-for="relationship in relationships" :key="relationship.id" :value="relationship" :selected="this.formEditMember.relationshipToHeadOfHousehold">{{relationship.name}}</option>
                                        </select>
                                        <div class="invalid-feedback">
                                            Please select your relationship to the head of household
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="emailEdit" class="col-form-label">
                                        <h6>Email:</h6>
                                    </label>
                                    <div class="">
                                        <input type="email" class="form-control" id="emailEdit" v-model="formEditMember.contactInfo.email">
                                        <div class="invalid-feedback">
                                            Please enter your email.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="phoneTypeEdit" class="col-form-label">
                                        <h6>Phone Type:</h6>
                                    </label>
                                    <div class="">
                                        <select v-if="formEditMember.contactInfo.phone.number && formEditMember.contactInfo.phone.number.length > 0" class="form-select" id="phoneTypeEdit" v-model="formEditMember.contactInfo.phone.typeId" required>
                                            <option v-for="phoneType in phoneTypes" :key="phoneType.id" :value="phoneType.id">{{phoneType.name}}</option>
                                        </select>
                                        <select v-else class="form-select" id="phoneTypeEdit" v-model="formEditMember.contactInfo.phone.typeId">
                                            <option v-for="phoneType in phoneTypes" :key="phoneType.id" :value="phoneType.id">{{phoneType.name}}</option>
                                        </select>
                                        <div class="invalid-feedback">
                                            Please select a phone type.
                                        </div>
                                    </div>
                                    <label for="phoneEdit" class="col-form-label">
                                        <h6>Phone Number:</h6>
                                    </label>
                                    <div class="">
                                        <input type="text" pattern="^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$" class="form-control" id="phoneEdit" v-model="formEditMember.contactInfo.phone.number">
                                        <div class="invalid-feedback">
                                            Please a 10-digit phone number.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="dobEdit" class="col-form-label">
                                        <h6>Year of Birth:</h6>
                                    </label>
                                    <div class="">
                                        <input type="date" class="form-control" id="dobEdit" v-model="formEditMember.dateOfBirth" required>
                                        <div class="invalid-feedback">
                                            Please enter your date of birth.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col mt-3">
                                    <button class="btn btn-primary d-flex flex-row float-end" type="submit">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Warning Modal -->
            <div class="modal" id="warningModal" tabindex="-1" aria-labelledby="warningModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header text-center">
                            <h5 class="modal-title" id="warningModalLabel">Warning</h5>
                            <button type="button" class="btn-close" @click="closeModalAfterValidation('warningModal')" aria-label="Close"></button>
                        </div>
                        <div class="modal-body" v-if="!hohCheck()">
                            <p>The Household does not have a member who is the Head Of HouseHold. Please assign a member as Head Of Household and try again</p>
                        </div>
                        <div class="modal-body" v-if="noJkWarning">
                            <p>You will be unable to proceed until you have selected a Jamatkhana for your household</p>
                        </div>
                        <div class="modal-body" v-if="missingDob.length">
                            <p>The following family member(s) do not have a valid date of birth and will be unable to register until it is provided:</p>
                            <ul v-for="member in missingDob" :key="member">
                                <li>{{ member }}</li>
                            </ul>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="closeModalAfterValidation('warningModal')">Go Back</button>
                            <button type="button" class="btn btn-primary" @click="continueToPayment" v-if="!noJkWarning && hohCheck()">Continue</button>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="cardOrTable">
                <div class="container memberCard px-2 py-2 rounded" v-for="(familyMember) in filteredFamilyMembers" :key="familyMember.memberId" @click.right.prevent @keydown="keydown" @copy.prevent @paste.prevent>
                    <div class="row">
                        <div class="col">{{familyMember.firstName + " " + familyMember.lastName }} </div>
                        <div class="col ">
                            <div class="row d-flex float-end me-1">{{familyMember.contactInfo.phone ? familyMember.contactInfo.phone.number : null}}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">{{familyMember.relationshipToHeadOfHousehold.name}}</div>
                        <div class="col">
                            <div class="row d-flex float-end me-1">{{familyMember.contactInfo.email}}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">{{familyMember.dateOfBirth}}</div>
                        <div class="col">
                            <div class="row d-flex float-end me-1">
                                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#editMemberModal" @click="populateEditMemberForm(familyMember.memberId)">Edit</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button type="button" class="btn btn-primary float-end mb-3" data-bs-toggle="modal" data-bs-target="#addMemberModal" @click="clearAddMemberForm()"><i class="bi bi-people-fill"></i> Add Family Member</button>
                    </div>
                </div>
                <div class="">
                    <div class="form-check checkbox-margin">
                        <input class="form-check-input" type="checkbox" value="" id="correctCheck" v-model="checked">
                        <label class="form-check-label" for="correctCheck">
                            <h6>All the information above is correct (please verify to proceed)</h6>
                        </label>
                    </div>
                    <br />
                    <div class="">
                        <button class="btn btn-primary d-flex flex-row float-end" v-bind:class="{ disabled: !checked }" @click="goToPayment">Proceed to Registration</button>
                    </div>
                </div>
                <div v-if="isInVolunteerFlow && volunteerInfo.resources.some(permission => permission.id === 1)" class="bottom-section">
                    <div class="comment-section">
                        <div><b>Comments</b></div>
                        <textarea
                            v-model="comments"
                            :rows="3"
                            :disabled="commentIsDisabled"
                            placeholder="Enter comments here"
                            ></textarea>
                    </div>
                    <div class="comment-buttons">
                        <button v-if="commentIsDisabled" type="button" class="btn btn-primary" @click="toggleComments(false)"> Edit Comments</button>
                        <button v-if="!commentIsDisabled" type="button" class="btn btn-secondary cancel-button" @click="toggleComments(true)"> Cancel</button>
                        <button v-if="!commentIsDisabled" type="button" class="btn btn-primary" @click="submitComments()" :disabled="saveCommentDisabled"> Save Comments</button>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="household-Member-Table pt-2">
                    <table class="table table-striped rounded">
                        <thead class="bg-primary text-white bg-gradient">
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Relationship</th>
                                <th>Date of Birth</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="table-row" v-for="(familyMember) in filteredFamilyMembers" :key="familyMember.memberId" @click.right.prevent @keydown="keydown" @copy.prevent @paste.prevent>
                                <td> {{ familyMember.firstName }}</td>
                                <td> {{ familyMember.lastName }} </td>
                                <!-- needs to map over all numbers and display?  -->
                                <td> {{ familyMember.contactInfo.phone ? familyMember.contactInfo.phone.number : null  }}</td>
                                <td> {{ familyMember.contactInfo.email }}</td>
                                <td> {{ familyMember.relationshipToHeadOfHousehold.name }}</td>
                                <td> {{ familyMember.dateOfBirth }}</td>
                                <td><button type=" button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#editMemberModal" @click="populateEditMemberForm(familyMember.memberId)">Edit</button>
                                </td>
                            </tr>
                            <tr>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td colspan="2">
                                    <button type="button" class="btn btn-primary float-end mb-3" data-bs-toggle="modal" data-bs-target="#addMemberModal" @click="clearAddMemberForm()"><i class="bi bi-people-fill"></i> Add Family Member</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="alert alert-danger alert-dismissible d-flex align-items-center fade show" v-if="verificationError != ''">
                    <i class="bi-exclamation-octagon-fill"></i>
                    <strong class="mx-2">Error!</strong> {{ verificationError }}
                    <br />
                </div>
                <div class="row bottom-section">
                    <div class="form-check col-8 checkbox-margin">
                        <input class="form-check-input" type="checkbox" value="" id="correctCheck" v-model="checked">
                        <label class="form-check-label" for="correctCheck">
                            <h5>All the information above is correct (please verify to proceed)</h5>
                        </label>
                    </div>
                    <div class="col">
                        <button class="btn btn-primary d-flex flex-row float-end" v-bind:class="{ disabled: (!checked || verificationError !== '') }" @click="goToPayment">Proceed to Registration</button>
                    </div>
                </div>
                <div v-if="isInVolunteerFlow && volunteerInfo.resources.some(permission => permission.id === 1)" class="row bottom-section">
                    <div class="comment-section">
                        <div><b>Comments</b></div>
                        <textarea
                            v-model="comments"
                            :rows="3"
                            :disabled="commentIsDisabled"
                            placeholder="Enter comments here"
                            ></textarea>
                    </div>
                    <div class="comment-buttons">
                        <button v-if="commentIsDisabled" type="button" class="btn btn-primary" @click="toggleComments(false)"> Edit Comments</button>
                        <button v-if="!commentIsDisabled" type="button" class="btn btn-secondary cancel-button" @click="toggleComments(true)"> Cancel</button>
                        <button v-if="!commentIsDisabled" type="button" class="btn btn-primary" @click="submitComments()" :disabled="saveCommentDisabled"> Save Comments</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <LoadingSpinner />
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import LoadingSpinner from '../components/LoadingSpinner.vue'
import NavigationBar from '../components/NavigationBar.vue';
import { verifyHeadOfHouseHold } from '../utils/MethodUtils.js';
import {
    ref,
    computed
} from 'vue'
import {
    Modal
} from 'bootstrap'
// import store from '../store/index.js';
import {
    useStore
} from 'vuex';
import {
    useRouter
} from 'vue-router';

export default {
    name: "UserInfoPage",
    components: {
        LoadingSpinner,
        NavigationBar
    },

    setup() {
        const store = useStore();
        const router = useRouter();
        const jamatkhanas = ref([]);
        const relationships = ref([]);
        const states = ref([]);
        const phoneTypes = ref([]);
        const showModal = ref(false)
        const cardOrTable = ref(false)
        const checked = ref(false)
        const loaded = ref(false)
        const familyInfo = ref({})
        const familyMembers = ref([])
        const headOfHouseholdID = ref(0)
        const verificationError = ref("")
        const region = ref({})
        const regions = ref([])
        const missingDob = ref([])
        const noJkWarning = ref(false)
        const isInVolunteerFlow = ref(store.getters.getIsInVolunteerFlow);
        const commentIsDisabled = ref(true)
        const saveCommentDisabled = ref(false)
        const previousComments = ref("")
        const comments = ref("")
        const volunteerInfo = ref(store.getters.getVolunteerInfo);
        const window = ref({
            width: 0
        })
        const formAddress = ref({
            headOfHouseholdMemberId: "",
            address: {
                line1: "",
                line2: "",
                city: "",
                state: {
                    id: "",
                    code: "",
                    name: "",
                },
                zipCode: "",
                country: "United States of America",
            }
        })
        const formJamatkhana = ref({
            headOfHouseholdMemberId: "",
            jamatkhana: {
                id: "",
                name: "",
            }
        })
        const formAddMember = ref({
            headOfHouseholdMemberId: "",
            firstName: "",
            lastName: "",
            relationshipToHeadOfHousehold: {
                id: "",
                name: "",
            },
            dateOfBirth: "",
            contactInfo: {
                phone: {
                    typeId: "",
                    number: "",
                },
                email: "",
                phones: [],
            }
        })
        const formEditMember = ref({
            headOfHouseholdMemberId: "",
            memberId: "",
            firstName: "",
            lastName: "",
            relationshipToHeadOfHousehold: {
                id: "",
                name: "",
            },
            dateOfBirth: "",
            contactInfo: {
                phone: {
                    typeId: "",
                    number: "",
                },
                email: "",
                phones: [],
            }
        })

        const filteredFamilyMembers = computed(() => {
            return familyMembers.value.filter(member => member.relationshipToHeadOfHousehold.id !== 88);
        })

        const getDetails = async () => {
            try {
                // clear info for volunteer flow
                store.commit('setHouseholdDetails', null)
                store.commit('setMembers', null)
                let selectedMemberId = store.getters.getSelectedMemberId;
                let params = selectedMemberId ? {
                    id: selectedMemberId
                } : null;
                const response = await axios.get("/api/v1/iiplatform/household", {
                    params
                });
                const {
                    success,
                    statusMessage
                } = response.data;
                if (success) {
                    const {
                        data
                    } = response.data;
                    familyInfo.value = data;
                    if (data.headOfHousehold) {
                        headOfHouseholdID.value = data.headOfHousehold.memberId;
                        familyMembers.value = [data.headOfHousehold].concat(data.familyMembers);
                    } else {
                        familyMembers.value = data.familyMembers;
                    }
                    for (let fm of familyMembers.value) {
                        if (fm.contactInfo && fm.contactInfo.phones && fm.contactInfo.phones.length > 0) {
                            fm.contactInfo.phone = {
                                typeId: fm.contactInfo.phones[0].type.id,
                                number: fm.contactInfo.phones[0].number,
                            }
                        }
                    }
                    store.commit('setHouseholdDetails', {
                        address: data.address,
                        jamatkhana: data.jamatkhana,
                        region: data.region,
                        headOfHousehold: data.headOfHousehold,
                        familyId: data.cdsNumber
                    })
                    const allMembers = familyMembers.value;
                    store.commit('setMembers', allMembers)
                    store.commit('setSelectedMemberId', null);
                    verificationError.value = verifyHeadOfHouseHold(allMembers);
                } else {
                    console.log(statusMessage)
                    router.push({
                        name: 'Error',
                        params: {
                            errorMsg: 'Verify Details - ' + statusMessage,
                        }
                    })
                }
                loaded.value = true
            } catch (err) {
                console.log(err)
                router.push({
                    name: 'Error',
                    params: {
                        errorMsg: 'Verify Details - ' + err,
                    }
                })
            }
        }

        const getComments = async () => {
            let params = {
                familyId: familyInfo.value.cdsNumber
            }
            try {
                const response = await axios.get('/api/v1/admin/comments', { params });
                const { success, statusMessage } = response.data;
                if (success) {
                    const { data } = response.data;
                    previousComments.value = data;
                    comments.value = data;
                } else {
                    console.log(statusMessage);
                }
            } catch (err) {
                console.log(err);
            }
        }

        const toggleComments = (value) => {
            if (value) {
                comments.value = previousComments.value
            }
            commentIsDisabled.value = value;
        }

        const submitComments = async () => {
            saveCommentDisabled.value = true;

            let body = {
                familyId: familyInfo.value.cdsNumber,
                comment: comments.value
            }
            try {
                const response = await axios.post('/api/v1/admin/comments', body);
                const { success, statusMessage } = response.data;
                if (success) {
                    commentIsDisabled.value = true;
                    previousComments.value = comments.value;
                } else {
                    console.log(statusMessage);
                }
            } catch (err) {
                console.log(err);
            }

            saveCommentDisabled.value = false;
        }

        return {
            loaded,
            getDetails,
            familyInfo,
            familyMembers,
            filteredFamilyMembers,
            commentIsDisabled,
            previousComments,
            comments,
            toggleComments,
            saveCommentDisabled,
            submitComments,
            getComments,
            volunteerInfo,
            showModal,
            jamatkhanas,
            relationships,
            formAddress,
            formJamatkhana,
            formAddMember,
            formEditMember,
            isInVolunteerFlow,
            states,
            region,
            regions,
            phoneTypes,
            cardOrTable,
            checked,
            missingDob,
            noJkWarning,
            window,
            headOfHouseholdID,
            verificationError
        };
    },

    async created() {
        this.loaded = false
        await this.getDetails()
        await this.getComments()
        const metaData = this.$store.getters.getMetadata
        this.region = this.familyInfo.region
        this.jamatkhanas = this.removeTimeStamps(metaData.jamatkhanas)
        this.relationships = metaData.relationships
        this.paymentMethods = metaData.paymentMethods
        this.phoneTypes = metaData.phoneNumberTypes
        this.regions = this.removeTimeStamps(metaData.regions)
        this.states = this.removeTimeStamps(metaData.states)
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
        this.loaded = true
    },

    async mounted() {
        this.window.width = window.innerWidth;
    },

    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {
        async editAddressInfo() {
            try {
                this.formAddress.address.state = this.formAddress.address.stateObj.code;
                this.formAddress.jamatkhanaId = this.formAddress.jamatkhana.id;
                this.formAddress.regionId = this.formAddress.jamatkhana.regionId;
                let response = await axios.put('/api/v1/iiplatform/household', this.formAddress);
                let {
                    success,
                    statusMessage
                } = response.data;
                if (!success) {
                    alert(statusMessage);
                }
            } catch (err) {
                console.log(err);
                alert(err);
            }
            this.loaded = false;
            this.closeModalAfterValidation("editAddressModal")
            this.getDetails();
        },
        async editJamatkhana() {
            this.formAddress.headOfHouseholdMemberId = this.headOfHouseholdID
            try {
                await axios.put('/api/v1/iiplatform/household/jamatkhana', this.formJamatkhana);
            } catch (err) {
                console.log(err);
            }
            this.getDetails();
            this.closeModalAfterValidation("editJamatkhanaModal")
        },
        async addMember() {
            try {
                // need to add validation 
                this.formAddMember.memberId = this.headOfHouseholdID;
                this.formAddMember.headOfHouseholdMemberId = this.headOfHouseholdID;
                this.updatePhones(this.formAddMember.contactInfo);
                let response = await axios.post('/api/v1/iiplatform/household/member', this.formAddMember);
                let {
                    success,
                    statusMessage
                } = response.data;
                if (!success) {
                    alert(statusMessage);
                }
            } catch (err) {
                console.error(err);
                alert(err);
            }
            this.loaded = false;
            this.closeModalAfterValidation("addMemberModal")
            this.getDetails();
            setTimeout(() => this.$refs.add_member_form.reset(), 300);
        },
        async editMember() {
            try {
                // check if we are losing a head of household with this update
                if (this.houseHoldHasHOH(this.formEditMember) != true) {
                    alert('ERROR - Unable to save changes: Household no longer has a member who is the Head Of HouseHold.  Please assign another member as Head Of Household and try again.');
                    this.closeModalAfterValidation("editMemberModal")
                    return;
                }
                this.updatePhones(this.formEditMember.contactInfo);
                let response = await axios.put('/api/v1/iiplatform/household/member/' + this.formEditMember.memberId, this.formEditMember);
                let {
                    success,
                    statusMessage
                } = response.data;
                if (!success) {
                    alert(statusMessage);
                }
            } catch (err) {
                console.error(err);
                alert(err);
            }
            this.loaded = false;
            this.closeModalAfterValidation("editMemberModal")
            this.getDetails();
        },
        handleResize() {
            this.window.width = window.innerWidth;
            if (this.window.width < 765) {
                this.cardOrTable = true
            } else {
                this.cardOrTable = false
            }
        },
        houseHoldHasHOH(editedMember) {
            if (editedMember.memberId != this.headOfHouseholdID) // we are not editing head of household
            {
                return true;
            }
            if (editedMember.relationshipToHeadOfHousehold.id === 0) {
                // the new relation is still head of household 
                return true;
            }
            for (let fm of this.familyMembers) {
                // if family member is head of household and not the member being edited
                // thrn we have another member who will be the new head of household
                if (fm.relationshipToHeadOfHousehold.id === 0 && fm.memberId != editedMember.memberId) {
                    return true;
                }
            }
            return false;
        },
        updatePhones(contactInfo) {
            if (contactInfo.phone && contactInfo.phone.number && contactInfo.phone.number.length > 0) {
                let foundIt = false;
                // check if we already have this phone
                if (contactInfo.phones && contactInfo.phones.length > 0) {
                    for (let p of contactInfo.phones) {
                        if (p.typeId == contactInfo.phone.typeId) {
                            foundIt = true;
                            p.number = contactInfo.phone.number.replace(/\D/g, "");
                            p.number = p.number.substr(p.number.length - 10);
                        }
                        // fix the phone format for existing ones otherwise GraphQL yells
                        if (p.number && p.number.length > 0) {
                            p.number = p.number.replace(/\D/g, "");
                            p.number = p.number.substr(p.number.length - 10);
                        }
                    }
                }
                if (!foundIt) //need to add this number
                {
                    let newPhone = {
                        typeId: contactInfo.phone.typeId,
                        number: contactInfo.phone.number.replace(/\D/g, "")
                    };
                    newPhone.number = newPhone.number.substr(newPhone.length - 10);
                    contactInfo.phones.push(newPhone);
                }
            }
        },
        populateEditAddress() {
            this.formAddress = {
                address: {
                    line1: this.familyInfo.address.line1,
                    line2: this.familyInfo.address.line2,
                    city: this.familyInfo.address.city,
                    stateObj: {
                        id: this.familyInfo.address.state.id,
                        code: this.familyInfo.address.state.code,
                        name: this.familyInfo.address.state.name,
                    },
                    zipCode: this.familyInfo.address.zipCode,
                    country: "United States of America",
                },
                jamatkhana: {
                    id: this.familyInfo.jamatkhana.id,
                    name: this.familyInfo.jamatkhana.name,
                    regionId: this.familyInfo.region.id
                },
                region: {
                    id: this.familyInfo.region.id,
                    name: this.familyInfo.region.name
                }

            }
        },
        populateEditJamatkhana() {
            this.formJamatkhana = {
                jamatkhana: {
                    id: this.familyInfo.jamatkhana.id,
                    name: this.familyInfo.jamatkhana.name,
                    regionId: this.region.id
                }
            }
        },
        populateEditMemberForm(mId) {
            let index = null;
            for (let i = 0; i < this.familyMembers.length; i++) {
                if (this.familyMembers[i].memberId === mId) {
                    index = i;
                    break;
                }
            }
            console.log(index)
            this.formEditMember = {
                headOfHouseholdMemberId: this.headOfHouseholdID,
                memberId: this.familyMembers[index].memberId,
                firstName: this.familyMembers[index].firstName,
                lastName: this.familyMembers[index].lastName,
                relationshipToHeadOfHousehold: this.familyMembers[index].relationshipToHeadOfHousehold,
                dateOfBirth: this.familyMembers[index].dateOfBirth,
                contactInfo: {
                    phone: {
                        typeId: this.familyMembers[index].contactInfo.phone ? this.familyMembers[index].contactInfo.phone.typeId : null,
                        number: this.familyMembers[index].contactInfo.phone ? this.familyMembers[index].contactInfo.phone.number : null,
                    },
                    email: this.familyMembers[index].contactInfo.email ? this.familyMembers[index].contactInfo.email : '',
                    phones: [],
                }
            }
            if (this.familyMembers[index].contactInfo.phones && this.familyMembers[index].contactInfo.phones.length > 0) {
                for (let p of this.familyMembers[index].contactInfo.phones) {
                    let newPhone = {
                        typeId: p.type.id,
                        number: p.number
                    };
                    this.formEditMember.contactInfo.phones.push(newPhone);
                }
            }
        },
        clearAddMemberForm() {
            this.formAddMember = {
                headOfHouseholdMemberId: "",
                firstName: "",
                lastName: "",
                relationshipToHeadOfHousehold: {
                    id: "",
                    name: "",
                },
                dateOfBirth: "",
                contactInfo: {
                    phone: {
                        typeId: "",
                        number: "",
                    },
                    email: "",
                    phones: [],
                }

            }
        },
        filterJamatkhanas(id) {
            let returnJks = []
            for (let jk of this.jamatkhanas) {
                if (jk.regionId === id) {
                    returnJks.push(jk)
                }
            }
            return returnJks
        },
        removeTimeStamps(array) {
            return array.map(({
                // eslint-disable-next-line
                createdAt,
                // eslint-disable-next-line
                updatedAt,
                ...rest
            }) => rest);
        },
        closeModalAfterValidation(modalId) {
            Modal.getInstance(document.getElementById(modalId)).hide()
        },
        hohCheck() {
            for (let fm of this.familyMembers) {
                if (fm.relationshipToHeadOfHousehold.id === 0) {
                    return true;
                }
            }
            return false;
        },
        goToPayment() {
            this.noJkWarning = false
            var myModal = new Modal(document.getElementById('warningModal'), {
                keyboard: false
            })
            if (!this.familyInfo.jamatkhana.id) {
                this.noJkWarning = true
                myModal.show()
            } else if (!this.hohCheck()) {
                myModal.show()
            } else {
                this.missingDob = []
                for (let fm of this.familyMembers) {
                    if (!fm.dateOfBirth) {
                        this.missingDob.push(`${fm.firstName} ${fm.lastName}`)
                    }
                }
                if (this.missingDob.length) {
                    myModal.show()
                } else {
                    this.$router.push('/payment')
                }
            }
        },
        clickViewPayments() {
            this.$router.push('/payments')
        },
        continueToPayment() {
            this.closeModalAfterValidation('warningModal')
            this.$router.push('/payment')
        }
    }
}
</script>

<style scoped>
.btn {
    font-weight: bold;
    color: white;
}

.address-card {
    border: 3px solid black;
    border-radius: 10px;
}

.memberCard {
    background-color: rgb(198, 224, 247);
    margin: 10px 0px;
}

.card-info span {
    font-size: 1.025rem;
}

@media(max-width: 600px) {
    .card-info span {
        font-size: 0.95rem;
    }
}

.smaller-hr {
    margin: 0.15rem auto;
}

.modal-open {
    overflow: scroll;
}

.checkbox-margin {
    margin-left: 15px;
}

.table-striped>tbody>tr:nth-child(2n+1)>td,
.table-striped>tbody>tr:nth-child(2n+1)>th {
    background-color: rgb(198, 224, 247);
}
.bottom-section {
    padding-bottom: 30px;
}
.comment-section {
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    width: 100%;
}
.comment-buttons {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.cancel-button {
    margin-right: 10px;
}
</style>
