import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'material-design-icons/iconfont/material-icons.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

createApp(App)
    .use(router)
    .use(store)
    .mount('#app')