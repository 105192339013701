<template>
<NavigationBar />
<div class="container">
    <div v-if="loaded" class="my-4">
        <button class="btn btn-success float-end" @click="addVolunteer"><i class="bi bi-person-circle"></i> Add Volunteer</button>
        <h5>Filters:</h5>
        <br />
        <div class="bg-primary text-white bg-gradient p-2 form-group">
            <label class="p-2"><b><i class="bi bi-search"></i> Search </b></label>
            <input type="search" v-model="filter" class="px-2 mw-75" />
        </div>

        <br /><br />
        <!-- if user has regional permissions -->
        <div v-if="currentVolunteer.regions" class="row">
            <div class="col">
                <label><b>Region: </b></label>
                <select class="form-select my-2" v-model="regionSelect" @change="submitFilter">
                    <option v-for="region in currentVolunteer.regions" :key="region.id" :value="region.id">
                        {{ region.name }}
                    </option>
                </select>
            </div>
            <div v-if="regionSelect" class="col">
                <label><b>Jamatkhana: </b></label>
                <select class="form-select my-2" v-model="jkSelect" @change="submitFilter">
                    <option v-for="jk in currentVolunteer.regions.filter(function (region) {return region.id == regionSelect})[0].jamatkhanas" :key="jk.id" :value="jk.id">
                        {{ jk.name }}
                    </option>
                </select>
            </div>
            <div v-if="regionSelect" class="col d-flex flex-column w-25">
                <button class="btn btn-primary mt-auto" @click="clearFilters">Clear Filters</button>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col">
                <label><b>Jamatkhana: </b></label>
                <select class="form-select my-2" v-model="jkSelect" @change="submitFilter">
                    <option v-for="jk in currentVolunteer.jamatkhanas" :key="jk.id" :value="jk.id">
                        {{ jk.name }}
                    </option>
                </select>
            </div>
            <div v-if="jkSelect" class="col d-flex flex-column w-25">
                <button class="btn btn-primary mt-auto" @click="clearFilters">Clear Filter</button>
            </div>
        </div>
        <br />
        <table class="table table-striped rounded-lg">
            <thead class="bg-primary text-white bg-gradient">
                <tr class="px-3">
                    <th class="text-center" @click="sort('name')" v-bind:class="[sortBy === 'name' ? sortDirection : '']">Name</th>
                    <th class="text-center" @click="sort('isActive')" v-bind:class="[sortBy === 'isActive' ? sortDirection : '']">Active</th>
                    <th class="text-center">Regions</th>
                    <th class="text-center">Jamatkhanas</th>
                    <th class="text-center">Permissions</th>
                    <th class="text-center">Edit</th>
                </tr>
            </thead>
            <tbody v-if="volunteerData">
                <tr class="table-row px-3 text-center align-middle" v-for="volunteer in paginatedVolunteerData" :key="volunteer.volunteerId" @click.right.prevent @keydown="keydown" @copy.prevent @paste.prevent>
                    <td> {{ volunteer.name }} </td>
                    <td>
                        {{ volunteer.isActive ? "Active" : "Inactive" }}
                        <div class="form-check form-switch" v-if="permissionCheck(volunteer)">
                            <input class="form-check-input mx-auto" type="checkbox" id="{{volunteer.name }}activation switch" v-model="volunteer.isActive" @change="handleActivationStatus(volunteer.volunteerId, volunteer.isActive)">
                        </div>
                    </td>
                    <td v-if="volunteer.regions"> {{ volunteer.regions.map(
                                    region => {return [region.name]}
                                    ).join(', ') }} </td>
                    <td v-else></td>
                    <td v-if="volunteer.jamatkhanas"> {{ volunteer.jamatkhanas.map(
                                    jk => {return [jk.name]}
                                    ).join(', ') }} </td>
                    <td v-else></td>
                    <td>
                        {{ volunteer.resources.map(
                            permission => {return [permission.description]}
                        ).join(', ') }}
                    </td>
                    <td><button class="btn btn-primary" @click="editVolunteer(volunteer.volunteerId)" v-if="permissionCheck(volunteer)">Edit</button></td>
                </tr>
            </tbody>
        </table>
        <div class="btn-toolbar d-flex justify-content-center" role="toolbar">
            <div class="btn-group" role="group">
                <button class="btn btn-primary btn-sm" :disabled="currentPage <= 1" @click="currentPage = 1"><i class="material-icons mt-1 md-18">skip_previous</i></button>
                <button class="btn btn-primary btn-sm" :disabled="currentPage <= 1" @click="prevPage"><i class="material-icons mt-1 md-18">fast_rewind</i></button>
            </div>
            <div class="my-auto mx-3">
                <span>Page {{ currentPage }} of {{ lastPageNum }}</span>
            </div>
            <div class="btn-group" role="group">
                <button class="btn btn-primary btn-sm" :disabled="currentPage >= lastPageNum" @click="nextPage"><i class="material-icons mt-1 md-18">fast_forward</i></button>
                <button class="btn btn-primary btn-sm" :disabled="currentPage >= lastPageNum" @click="lastPage"><i class="material-icons mt-1 md-18">skip_next</i></button>
            </div>
        </div>
    </div>
    <div v-else>
        <LoadingSpinner />
    </div>
</div>
</template>

<script>
import axios from 'axios';
import {
    ref
} from 'vue';
import NavigationBar from '../components/NavigationBar.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue'

export default {
    name: "HomePage",
    components: {
        NavigationBar,
        LoadingSpinner,
    },

    setup() {
        const loaded = ref(false);
        const volunteerData = ref([])
        const currentVolunteer = ref()
        const volunteerIsActive = ref()
        const regionSelect = ref()
        const jkSelect = ref()
        const sortBy = ref('name')
        const sortDirection = ref('asc')
        const filter = ref('')
        const currentPage = ref(1)
        const pageSize = ref(10)

        return {
            volunteerData,
            loaded,
            currentVolunteer,
            volunteerIsActive,
            regionSelect,
            jkSelect,
            sortBy,
            sortDirection,
            filter,
            currentPage,
            pageSize,
        }
    },
    async created() {
        this.currentVolunteer = this.$store.getters.getVolunteerInfo;
        if (!this.currentVolunteer.resources.some(permission => permission.id === 2)) {
            this.$router.push("/search");
        }
        const {
            data
        } = await axios.get("/api/v1/admin/volunteers");
        if (data.success) {
            this.volunteerData = this.filterVolunteerData(data.data);
        }
        this.loaded = true
    },

    methods: {
        sort(s) {
            if (s === this.sortBy) {
                this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
            }
            this.sortBy = s;
        },
        nextPage() {
            if ((this.currentPage * this.pageSize) < this.sortedVolunteerData.length) this.currentPage++
        },
        prevPage() {
            if (this.currentPage > 1) this.currentPage--
        },
        lastPage() {
            this.currentPage = Math.ceil(this.sortedVolunteerData.length / this.pageSize)
        },
        editVolunteer(id) {
            this.$store.commit('setSelectedMemberId', id);
            this.$router.push("/volunteer/edit");
        },
        addVolunteer() {
            this.$router.push("/volunteer/search");
        },
        async handleActivationStatus(id, bool) {
            try {
                let response = await axios.put('/api/v1/admin/volunteerActivation', {
                    volunteerId: id,
                    isActive: bool,
                });
                let {
                    success,
                    statusMessage
                } = response.data;
                if (!success) {
                    console.log(statusMessage);
                }
            } catch (err) {
                console.error(err);
            }
        },
        async submitFilter() {
            let filter = ""
            if (this.jkSelect) {
                filter = `jamatkhanaId=${this.jkSelect}`
            } else {
                filter = `regionId=${this.regionSelect}`
            }
            try {
                const {
                    data
                } = await axios.get("/api/v1/admin/volunteers?" + filter)
                if (data.success) {
                    this.volunteerData = this.filterVolunteerData(data.data);
                }
            } catch (err) {
                console.error(err);
            }
        },
        async clearFilters() {
            const {
                data
            } = await axios.get("/api/v1/admin/volunteers");
            if (data.success) {
                this.volunteerData = this.filterVolunteerData(data.data);
            }
            this.regionSelect = null
            this.jkSelect = null
        },
        permissionCheck(targetVolunteer) {
            let checker = (arr, target) => target.every(el => arr.some(elem => elem.id === el.id));
            if (this.currentVolunteer.regions) {
                if (targetVolunteer.regions) {
                    if (!checker(this.currentVolunteer.regions, targetVolunteer.regions)) {
                        return false
                    }
                }
            }
            if (targetVolunteer.regions && !this.currentVolunteer.regions) {
                return false
            }
            if (targetVolunteer.jamatkhanas && !this.currentVolunteer.regions) {
                if (!checker(this.currentVolunteer.jamatkhanas, targetVolunteer.jamatkhanas)) {
                    return false
                }
            }
            if (!checker(this.currentVolunteer.resources, targetVolunteer.resources)) {
                return false
            }
            return true
        },
        filterVolunteerData(data) {
            return data.map(item => {
                const newMap = {};
                newMap.name = item.volunteer.name
                newMap.isActive = item.isActive
                newMap.regions = item.regions
                newMap.jamatkhanas = item.jamatkhanas
                newMap.resources = item.resources
                newMap.volunteerId = item.volunteer.id
                return newMap
            })
        }
    },
    watch: {
        filter() {
            this.currentPage = 1;
        }
    },
    computed: {
        searchVolunteerData() {
            return this.volunteerData.filter(v => {
                if (this.filter == '') return true;
                return v.name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0;
            })
        },
        sortedVolunteerData: function () {
            // eslint-disable-next-line
            return this.searchVolunteerData.sort((v1, v2) => {
                let modifier = 1;
                if (this.sortDirection === 'desc') modifier = -1;
                if (v1[this.sortBy] < v2[this.sortBy]) return -1 * modifier;
                if (v1[this.sortBy] > v2[this.sortBy]) return 1 * modifier;
                return 0;
            })
        },
        paginatedVolunteerData() {
            return this.sortedVolunteerData.filter((row, index) => {
                let start = (this.currentPage - 1) * this.pageSize
                let end = this.currentPage * this.pageSize
                if (index >= start && index < end) return true;
            })
        },
        lastPageNum() {
            return Math.ceil(this.sortedVolunteerData.length / this.pageSize)
        },
    },
}
</script>

<style scoped>
.table-striped>tbody>tr:nth-child(2n+1)>td,
.table-striped>tbody>tr:nth-child(2n+1)>th {
    background-color: rgb(220, 235, 249);
}

.asc:after {
    content: "\25B2"
}

.desc:after {
    content: "\25BC"
}
</style>
