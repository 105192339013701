<template>
<div>
    <NavigationBar />
    <div class="container" style="padding-top: 20px;">
        <div class="alert alert-danger alert-dismissible d-flex align-items-center fade show" v-if="error">
            <i class="bi-exclamation-octagon-fill"></i>
            <strong class="mx-2">Error!</strong> {{ error }}
            <br />
        </div>
        <h2>Unregistered Members Report</h2>
        <div class="searchInputs">
            <div class="searchInputRow">
                <div class="searchInputItem" v-if="regions">
                    <label for="region" class="col-form-label"><b>Region</b></label>
                    <select class="form-select" id="region" v-model="region">
                        <option value="-1" selected>Please select</option>
                        <option v-for="region in regions" :value="region.id" :key="region.id">{{region.name}}</option>
                    </select>
                </div>
                <div class="searchInputItem">
                    <label for="jamatkhana" class="col-form-label"><b>Jamatkhana</b>  </label>
                    <select class="form-select" id="jamatkhana" v-model="jamatkhana" :disabled="region === '-1'">
                        <option v-if="regions" value="-1" selected>All Jamatkhanas</option>
                        <option v-for="jk in filterJamatkhanas(region)" :value="jk.id" :key="jk.id">{{jk.name}}</option>
                    </select>
                </div>
            </div>
            <div class="searchInputRow">
                <div class="searchInputItem">
                    <button class="col-auto btn btn-success" :disabled="disableRunReport" @click="runReport">Run Report</button>
                </div>
            </div>
        </div>
        <div v-if="loadingReport">
            <LoadingSpinner />
        </div>
        <div v-if="report && !loadingReport">
            <div class="reportOptions">
                <button class="btn btn-primary" @click="printReport()">Print</button>
            </div>
            <h4>Unregistered Members Report</h4>
            <div class="table-responsive household-Member-Table pt-2" style="padding-bottom: 20px;" v-for="jk in report" :key="jk.jamatkhanaId">
                <table class="table">
                    <thead>
                        <tr><th class="table-primary" colspan="7">{{jk.jamatkhanaName}}</th></tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><b>Name</b></td>
                            <td><b>Payment ID</b></td>
                            <td><b>Family Location</b></td>
                            <td></td>
                        </tr>
                        <tr v-for="member in jk.members" :key="member.memberId">
                            <td>{{ member.firstName }} {{ member.lastName }}</td>
                            <td><div class="link" @click="clickViewDetails(member.paymentInfo.paymentId)">{{member.paymentInfo.paymentId}}</div></td>
                            <td>{{getJamatkhanaName(member.paymentInfo.coverageLocation)}}</td>
                            <td> <button 
                                    class="btn btn-link btn-sm" 
                                    data-bs-toggle="modal" 
                                    data-bs-target="#showContactModal"
                                    @click="showContactInfo(member.id, member.paymentInfo.paymentInitiatedBy)">Contact Info
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="modal" id="showContactModal" tabindex="-1" aria-labelledby="showContactModal" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div v-if="selectedMember && !loadingModal" class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="showContactModalLabel">Contact Info</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="card previewNote">
                                    <div class="card-body">
                                        <b>Name:</b> {{ selectedMember.firstName }} {{ selectedMember.lastName }}<br>
                                        <div v-if="selectedMember.email">
                                            <b>Email:</b>&nbsp;
                                                <a :href="'mailto:' + `${selectedMember.email ? selectedMember.email : ''}`">{{ selectedMember.email ? selectedMember.email : '' }}</a>&nbsp;
                                                <button class=" btn btn-sm btn-primary" @click="copyEmail">Copy</button>
                                            <br>
                                        </div>
                                        <div v-if="selectedMember.phone && selectedMember.phone.length > 0">
                                            <div v-for="p in selectedMember.phone" :key="p.id">
                                                <b>{{p.name + ": " }}</b> {{p.number}}
                                            </div>
                                        </div>
                                        <div>
                                            <br><b>Previous Payment Contact Info</b><br>
                                            <b>Initiated By:</b> {{ initiatedMember.firstName }} {{ initiatedMember.lastName }}<br>
                                            <div v-if="initiatedMember.email">
                                                <b>Email:</b>&nbsp;
                                                    <a :href="'mailto:' + `${initiatedMember.email ? initiatedMember.email : ''}`">{{ initiatedMember.email ? initiatedMember.email : '' }}</a>&nbsp;
                                                    <button class=" btn btn-sm btn-primary" @click="copyEmail(2)">Copy</button>
                                                <br>
                                            </div>
                                            <div v-if="initiatedMember.phone && initiatedMember.phone.length > 0">
                                                <div v-for="p in initiatedMember.phone" :key="p.id">
                                                    <b>{{p.name + ": " }}</b> {{p.number}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="modal-header">
                                <h5 class="modal-title" id="showContactModalLabel">Loading</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="card previewNote">
                                    <div class="card-body">
                                        Fetching contact info...
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    </div>
</div>

</template>

<script>
import axios from 'axios';
import NavigationBar from '../components/NavigationBar.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import { displayInUSD, getLocalTimestampfromUtcTimeStamp, getVolunteerJks } from '../utils/MethodUtils.js';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Modal } from 'bootstrap';

export default {
    name: "UnregisteredMembersReportPage",
    components: {
        NavigationBar,
        LoadingSpinner
    },
    setup() {
        const store = useStore();
        const router = useRouter();

        const regions = ref(store.getters.getMetadata.regions);
        const region = ref("-1");
        const jamatkhanas = ref(store.getters.getMetadata.jamatkhanas);
        const jamatkhana = ref("-1");
        const error = ref(null);
        const loadingReport = ref(false);
        const loadingModal = ref(true);
        const report = ref();
        const reportName = ref();
        const selectedMember = ref();
        const initiatedMember = ref();

        const filterJamatkhanas = (id) => {
            let returnJks = []
            for (let jk of jamatkhanas.value) {
                if (jk.regionId === id) {
                    returnJks.push(jk)
                }
            }
            return returnJks
        }

        const disableRunReport = computed(() => {
            return region.value == "-1";
        })

        const getPaymentMethod = (id) => {
            const methods = store.getters.getMetadata.paymentMethods;
            const filteredMethod = methods.filter(method => method.id === id);
            if (filteredMethod.length === 1) {
                return filteredMethod[0].name
            } else {
                return id;
            }
        }

        const getJamatkhanaName = (id) => {
            const methods = store.getters.getMetadata.jamatkhanas;
            const filteredMethod = methods.filter(method => method.id === id);
            if (filteredMethod.length === 1) {
                return filteredMethod[0].name
            } else {
                return id;
            }
        }

        const getRegionName = (id) => {
            const methods = store.getters.getMetadata.regions;
            const filteredMethod = methods.filter(method => method.id === id);
            if (filteredMethod.length === 1) {
                return filteredMethod[0].name
            } else {
                return id;
            }
        }

        const printReport = () => {
            window.print();
        }

        const runReport = async () => {
            error.value = null;
            loadingReport.value = true;
            let params = {}
            if (jamatkhana.value != "-1") {
                params.jamatkhanaId = jamatkhana.value;
                reportName.value = getJamatkhanaName(jamatkhana.value);
            } else {
                params.regionId = region.value;
                reportName.value = getRegionName(region.value);
            }
            try {
                const response = await axios.get('/api/v1/admin/reports/unregisteredmembers', { params });
                const { success, statusMessage } = response.data;
                if (success) {
                    const { data } = response.data;
                    report.value = data;
                } else {
                    console.log(statusMessage);
                    error.value=statusMessage;
                }
            } catch (err) {
                console.log(err);
                error.value = err
            }
            loadingReport.value = false;
        }

        const clickViewDetails = async (paymentID) => {
            let routeData = router.resolve({name: 'PaymentStatus', params: {paymentId: paymentID}});
            window.open(routeData.href, '_blank');
        }

        const closeModal = (modalId) => {
            Modal.getInstance(document.getElementById(modalId)).hide()
        }

        const showContactInfo = async (memberId, initiatedMemberId) => {
            try
            {
                error.value = null;
                loadingModal.value = true;

                const memberRequest = axios.get("/api/v1/iiplatform/search", { params: { memberId } });
                const initiatedMemberRequest = axios.get("/api/v1/iiplatform/search", { params: { memberId: initiatedMemberId } });

                const [memberResponse, initiatedMemberResponse] = await Promise.all([memberRequest, initiatedMemberRequest]);

                const { success: memberSuccess, data: memberData } = memberResponse.data;
                if (memberSuccess && memberData && memberData.length > 0) {
                    selectedMember.value = memberData[0]; // Store member info
                } else {
                    error.value = memberResponse.data.statusMessage;
                    closeModal("showContactModal");
                }

                const { success: initiatedMemberSuccess, data: initiatedMemberData } = initiatedMemberResponse.data;
                if (initiatedMemberSuccess && initiatedMemberData.length > 0) {
                    initiatedMember.value = initiatedMemberData[0]; // Store initiated member info
                } else {
                    error.value = initiatedMemberResponse.data.statusMessage;
                    closeModal("showContactModal");
                }

                loadingModal.value = false;
            }
            catch (err) {
                console.log(err);
                error.value = err;
                closeModal("showContactModal");
            }
        }

        const copyEmail = async (member) => {
            try {
                let emailToCopy = selectedMember.value.email ? selectedMember.value.email : '';
                if (member === 2) {
                    emailToCopy = initiatedMember.value.email ? initiatedMember.value.email : '';
                }
                await navigator.clipboard.writeText(emailToCopy);
            } catch (e) {
                console.log(e)
            }
        }

        return {
            copyEmail,
            clickViewDetails,
            getLocalTimestampfromUtcTimeStamp,
            loadingModal,
            printReport,
            getJamatkhanaName,
            getPaymentMethod,
            loadingReport,
            disableRunReport,
            filterJamatkhanas,
            report,
            reportName,
            runReport,
            showContactInfo,
            regions,
            region,
            jamatkhanas,
            jamatkhana,
            selectedMember,
            initiatedMember,
            error
        }

    },
    async created() {
        let volunteer = this.$store.getters.getVolunteerInfo;
        this.regions = volunteer.regions;
        if(!this.regions) // we only have jks
        {
            let volJkInfo = getVolunteerJks();
            this.jamatkhanas = volJkInfo.jamatkhanas;
            this.jamatkhana = volJkInfo.selectedJamatkhana;
            this.region = this.jamatkhanas[0].regionId;        
        }
        if (!volunteer.resources.some(permission => permission.id === 12)) {
            this.$router.push("/search");
        }
    }
}
</script>

<style scoped>
.searchInputs {
    padding-bottom: 20px;
}
.searchInputRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}
.searchInputRow:nth-last-child(2) {
    padding-bottom: 10px;
}
.searchInputItem {
    padding-right: 20px;
    min-width: 200px;
}
.reportOptions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.link {
    color: #0d6efd;
    text-decoration: underline;
}
.link:hover {
    cursor: pointer;
}
.previewNote {
    margin-bottom: 20px;
}
</style>
