<template>
    <div>
        <div>
            <label for="paymentId"><b>Payment ID</b></label>
            <input type="text" id="paymentId" v-model="paymentId" required>
            <button v-on:click="fetchPaymentClicked">Fetch Payment Details</button>
        </div>
        <label>{{ pageStatus }}</label>
        <div v-if="paymentDetails && paymentDetails.members">
            <table>
                <thead>
                    <tr>
                        <th>MemberId</th>
                        <th>Original Amount</th>
                        <th>Subsidized Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="member in paymentDetails.members" :key="member.memberId">
                        <td>{{ member.memberId }}</td>
                        <td>{{ member.rate }}</td>
                        <td>
                            <input type="number" v-model="member.subsidizedRate" required>
                        </td>
                    </tr>
                    <tr>
                        <td><b>DONATION</b></td>
                        <td>{{ paymentDetails.donation }}</td>
                        <td>{{ paymentDetails.donation }}</td>
                    </tr>
                    <tr>
                        <td><b>TOTAL</b></td>
                        <td>{{ paymentDetails.total }}</td>
                        <td>{{ subsidizedTotal }}</td>
                    </tr>
                </tbody>
            </table>
            <div>
                <label for="subsidizedBy"><b>Subsidized By</b></label>
                <input type="number" id="subsidizedBy" v-model="subsidizedBy" required>
            </div>
            <div>
                <label for="subsidyReason"><b>Reason</b></label>
                <input type="subsidyReason" id="subsidyReason" v-model="subsidyReason" required>
            </div>

            <button v-on:click="applySubsidyClicked">Apply Subsidy</button>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import NavigationBar from '../components/NavigationBar.vue';

export default {
    name: "SubsidizePage",

    data() {
        return {
            paymentId: "",
            pageStatus: "",
            paymentDetails: "",
            subsidizedBy: "",
            subsidyReason: ""
        }
    },

    computed: {
        subsidizedTotal() {
            let sum = 0;
            for (const member of this.paymentDetails.members) {
                sum += member.subsidizedRate;
            }
            sum += this.paymentDetails.donation;
            return sum;
        }
    },

    methods: {
        async fetchPaymentClicked() {
            this.pageStatus = "Loading ..."
            try {
                const response = await axios.get(`/api/v1/burial/payment/${this.paymentId}`);
                this.pageStatus = ""
                const { success, statusMessage } = response.data;
                if (success) {
                    const { 
                        data: {
                            members,
                            donation,
                            total
                        }
                    } = response.data;
                    this.paymentDetails = {
                        members: members.map((member) => {
                            return {
                                ...member,
                                subsidizedRate: member.rate
                            }
                        }),
                        donation,
                        total
                    };
                }
                else {
                    this.pageStatus = statusMessage;
                }

            }
            catch (err) {
                console.error(err);
                this.pageStatus = "Error !!!"
            }
        },

        async applySubsidyClicked() {
            const payload = {
                paymentId: this.paymentId,
                paymentDetails: {
                    ...this.paymentDetails,
                    subsidizedTotal: this.subsidizedTotal
                },
                subsidizedBy: this.subsidizedBy,
                subsidyReason: this.subsidyReason
            };

            try {
                const response = await axios.post("/api/v1/burial/subsidy", payload);
                const {success, statusMessage} = response.data;
                if (!success) {
                    this.pageStatus = statusMessage;
                }
                else {
                    this.$router.push({ name: 'PaymentStatus', params: {paymentId: this.paymentId }})
                }
            }
            catch (err) {
                console.error(err);
                this.pageStatus = "Server error while applying subsidy"
            }
            finally {
                this.paymentId = ""
                this.paymentDetails = ""
                this.subsidizedBy = ""
                this.subsidyReason = ""
            }
        }
    }
}

</script>