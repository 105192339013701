<template>
<NavigationBar />
<div class="d-flex flex-row align-items-center justify-content-center">
    <div class="container">
        <br />
        <h4>Edit Pricing</h4>
        <br />
        <div class="alert alert-danger alert-dismissible d-flex align-items-center fade show" v-if="error != ''">
            <i class="bi-exclamation-octagon-fill"></i>
            <strong class="mx-2">Error!</strong> {{ error }}
            <br />
        </div>
        <div class="alert alert-success alert-dismissible d-flex align-items-center fade show" v-if="success != ''">
            <i class="bi-check-circle-fill"></i>
            <strong class="mx-2">Success!</strong> {{ success }}
        </div>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th v-if="volunteer.regions">
                        Region:
                        <select class="form-select" id="regionSelect" @change="regionChange(this.region)" v-model="region">
                            <option value="-1" selected>Please select</option>
                            <option v-for="region in volunteer.regions" :value="region.id" :key="region.id">{{region.name}}</option>
                        </select>
                    </th>
                    <th colspan="3" class="text-center">Price For Individuals</th>
                </tr>
                <tr>
                    <th>Location(JK)</th>
                    <th class="text-center" v-for="ageRange in ageRanges" :key="ageRange">
                        {{ ageRange.olderThan }} {{ (ageRange.youngerThan >= 200) ? ("+") : (" - " + ageRange.youngerThan)}}
                    </th>
                </tr>
            </thead>
            <tbody v-if="prices">
                <tr class="table-row" v-for="price in prices" :key="price">
                    <td v-if="price[0].jamatkhana.name.includes('Region')"><b> {{ price[0].jamatkhana.name }}</b></td>
                    <td v-else> {{ price[0].jamatkhana.name }}</td>
                    <td v-for="(val, index) in price" :key="index">
                        <CurrencyInput v-model="val.rate" :options="{ currency: 'USD', precision: 2, valueRange: {min: 0} }"></CurrencyInput>
                    </td>
                    <td v-if="price[0].jamatkhana.name.includes('Region')"><button class="btn btn-primary d-flex flex-row" @click="confirmRegionSubmission(price)">Save</button></td>
                    <td v-else><button class="btn btn-primary d-flex flex-row" @click="submitPrices(price, this.region)">Save</button></td>
                </tr>
            </tbody>
        </table>
        <!-- Confirmation Modal -->
        <div class="modal" id="warningModal" tabindex="-1" aria-labelledby="warningModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header text-center">
                            <h5 class="modal-title" id="warningModalLabel">Warning</h5>
                            <button type="button" class="btn-close" @click="closeModalAfterValidation('warningModal')" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p>Applying this will overwrite prices for all the Jamatkhanas for this region. Do you wish to procced? </p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="closeModalAfterValidation('warningModal')">Go Back</button>
                            <button type="button" class="btn btn-primary" @click="submitPrices(this.regionPrice, this.region)">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
// import LoadingSpinner from '../components/LoadingSpinner.vue'
import NavigationBar from '../components/NavigationBar.vue';
import CurrencyInput from '../components/CurrencyInput.vue';
import {
    ref
} from 'vue'
import {
    displayInUSD
} from '../utils/MethodUtils.js';
import {
    useStore
} from 'vuex';
import {
    useRouter
} from 'vue-router';
import {
    Modal
} from 'bootstrap';

export default {
    name: "SetPrices",
    components: {
        NavigationBar,
        CurrencyInput,
        // LoadingSpinner
    },
    setup() {
        const regions = ref([]);
        const prices = ref([])
        const ageRanges = ref([])
        const error = ref("")
        const success = ref("")
        const region = ref("")
        const volunteer = ref()
        const regionPrice = ref([])

        const store = useStore();
        const router = useRouter();

        let isInVolunteerFlow = store.getters.getIsInVolunteerFlow;
        if (isInVolunteerFlow === false) {
            router.push("/notfound");
        }

        const regionChange = async (id) => {
            try {
                error.value = ""
                const response = await axios.get('/api/v1/admin/rates?regionId=' + id);
                const {
                    success,
                    statusMessage
                } = response.data;
                if (success) {
                    const {
                        data
                    } = response.data;
                    prices.value = data.reduce((r, a) => {
                        r[a.jamatkhana.id] = [...r[a.jamatkhana.id] || [], a];
                        return r
                    }, {})
                    if (regions.value.filter(region => region.id === id)[0]) {
                        let regionName = regions.value.filter(region => region.id === id)[0].name;
                        let regionRates = JSON.parse(JSON.stringify(Object.values(prices.value)[0]));
                        prices.value["region"] = regionRates.map((r) => {
                            r.jamatkhana.id = "region"
                            r.jamatkhana.name = `${regionName} Region`
                            return r
                        })
                    }
                    if (data && data.length > 0) {
                        ageRanges.value = [...new Map(data.map(item => [item.age_group['id'], item.age_group])).values()];
                    }
                } else {
                    console.log(statusMessage);
                    error.value = statusMessage;
                }
            } catch (err) {
                console.log(err);
                error.value = err;
            }
        }
        return {
            regionChange,
            displayInUSD,
            regions,
            prices,
            ageRanges,
            error,
            success,
            region,
            volunteer, 
            regionPrice
        }
    },
    async created() {
        this.regions = this.$store.getters.getMetadata.regions
        this.volunteer = this.$store.getters.getVolunteerInfo;
        if (!this.volunteer.resources.some(permission => permission.id === 5)) {
            this.$router.push("/search");
        }
    },
    methods: {
        async submitPrices(price, regionId) {
            try {
                this.error = ""
                this.success = ""
                let rates = [];
                let jk = price[0].jamatkhana;
                if (price[0].jamatkhana.id === "region") {
                    let jkIds = Object.keys(this.prices);
                    for (let jkId of jkIds) {
                        if (jkId != "region") {
                            for (let ageRate of price) {
                                if (ageRate.rate == null) {
                                    this.error = `Rates for each age group must be filled for the ${price[0].jamatkhana.name} region`;
                                    return
                                }
                                rates.push({
                                    jamatkhanaId: jkId,
                                    ageRangeId: ageRate.age_group.id,
                                    rate: ageRate.rate
                                });
                            }
                        }
                    }
                    this.closeModalAfterValidation('warningModal')
                } else {
                    let jk = price[0].jamatkhana;
                    for (let ageRate of price) {
                        if (ageRate.rate == null) {
                            this.error = "Rates for each age group must be filled for Jamatkhana: " + jk.name;
                            return
                        }
                        rates.push({
                            jamatkhanaId: jk.id,
                            ageRangeId: ageRate.age_group.id,
                            rate: ageRate.rate
                        });
                    }
                }
                let output = {
                    regionId: regionId,
                    rates: rates
                }
                const response = await axios.put('/api/v1/admin/rates', output);
                const {
                    success,
                    statusMessage
                } = response.data;
                if (success) {
                    if (price[0].jamatkhana.id === "region") {
                        this.success = `${price[0].jamatkhana.name} was succesfully saved`;
                    } else {
                        this.success = `${jk.name} was succesfully saved`;
                    }
                    this.regionChange(regionId);
                } else {
                    console.log(statusMessage);
                    this.error = statusMessage;
                }
            } catch (err) {
                console.log(err)
                this.error = err
                return
            }
        },
        closeModalAfterValidation(modalId) {
            Modal.getInstance(document.getElementById(modalId)).hide()
        },
        confirmRegionSubmission(price) {
            this.regionPrice = price
            var myModal = new Modal(document.getElementById('warningModal'), {
                keyboard: false
            })
            myModal.show()
        }
    }
}
</script>

<style scoped>
.alert i[class^="bi-"] {
    font-size: 1.5em;
    line-height: 1;
}
</style>
