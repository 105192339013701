<template>
<div>
    <NavigationBar />
    <div class="container" style="padding-top: 20px;">
        <div class="alert alert-danger alert-dismissible d-flex align-items-center fade show" v-if="error">
            <i class="bi-exclamation-octagon-fill"></i>
            <strong class="mx-2">Error!</strong> {{ error }}
            <br />
        </div>
        <h2>Payments in Batch {{batchId}}</h2>
        <div v-if="loadingReport">
            <LoadingSpinner />
        </div>
        <div v-if="reportPayments.length > 0 && !loadingReport" class="tableResults">
            <table class="table">
                <tbody>
                    <tr>
                        <td><b>Batch ID</b></td>
                        <td><b>Payment ID</b></td>
                        <td><b>Amount</b></td>
                        <td><b>Payment Status</b></td>
                        <td><b>Initiated On</b></td>
                        <td><b>Initiated By</b></td>
                        <td><b>Member ID</b></td>
                        <td><b>Coverage Location</b></td>
                    </tr>
                    <tr v-for="payment in reportPayments" :key="payment.paymentId">
                        <td>{{payment.batchId}}</td>
                        <td><div class="link" @click="clickViewDetails(payment.paymentId)">{{payment.paymentId}}</div></td>
                        <td>{{displayInUSD(payment.amount)}}</td>
                        <td>{{getPaymentStatusName(payment.paymentStatus)}}</td>
                        <td>{{getLocalTimestampfromUtcTimeStamp(payment.initiatedOn)}}</td>
                        <td>{{payment.initiatedBy.name}}</td>
                        <td>{{payment.initiatedBy.id}}</td>
                        <td>{{getJamatkhanaName(payment.locationId)}}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td><b>Total:</b></td>
                        <td><b>{{displayInUSD(reportSummary.totalAmount)}}</b></td>
                        <td></td>
                        <td></td>
                        <td><b>Payments in Batch:</b></td>
                        <td>{{reportSummary.count}}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>    
    </div>
</div>

</template>

<script>
import axios from 'axios';
import NavigationBar from '../components/NavigationBar.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import { displayInUSD, getLocalTimestampfromUtcTimeStamp } from '../utils/MethodUtils.js';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
    name: "BatchPaymentsReportPage",
    components: {
        NavigationBar,
        LoadingSpinner
    },
    props:['batchId'],
    setup(props) {
        const store = useStore();
        const router = useRouter();
        const reportPayments = ref([]);
        const reportSummary = ref();

        const error = ref(null);
        const successMessage = ref(null);
        const loadingReport = ref(false);

        const clickViewDetails = async (paymentID) => {
            let routeData = router.resolve({name: 'PaymentStatus', params: {paymentId: paymentID}});
            window.open(routeData.href, '_blank');
        }

        const getJamatkhanaName = (id) => {
            const methods = store.getters.getMetadata.jamatkhanas;
            const filteredMethod = methods.filter(method => method.id === id);
            if (filteredMethod.length === 1) {
                return filteredMethod[0].name
            } else {
                return id;
            }
        }

        const getPaymentStatusName = (id) => {
            const methods = store.getters.getMetadata.paymentStatus;
            const filteredMethod = methods.filter(method => method.id === id);
            if (filteredMethod.length === 1) {
                return filteredMethod[0].name
            } else {
                return id;
            }
        }

        const getBatchPayments = async () => {
            error.value = null;
            loadingReport.value = true;

            let params = {
                batchId: props.batchId,
            }

            try {
                const response = await axios.get('/api/v1/admin/reports/batchpayments', { params });
                const { success, statusMessage } = response.data;
                if (success) {
                    const { data } = response.data;
                    reportPayments.value = data.payments;
                    reportSummary.value = data.batchDetails;
                    if (data.payments.length === 0) {
                        error.value="No payments found in the batch";
                    }
                } else {
                    console.log(statusMessage);
                    error.value=statusMessage;
                }
            } catch (err) {
                console.log(err);
                error.value = err
            }

            loadingReport.value = false;
        }

        getBatchPayments();

        return {
            displayInUSD,
            getLocalTimestampfromUtcTimeStamp,
            clickViewDetails,
            getJamatkhanaName,
            getPaymentStatusName,
            loadingReport,
            error,
            successMessage,
            reportPayments,
            reportSummary
        }

    },
    async created() {
        let volunteer = this.$store.getters.getVolunteerInfo;
        if (!volunteer.resources.some(permission => permission.id === 10)) {
            this.$router.push("/search");
        }
    }
}
</script>

<style scoped>
.searchInputs {
    padding-bottom: 20px;
}
.searchInputRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}
.searchInputRow:nth-last-child(2) {
    padding-bottom: 10px;
}
.searchInputItem {
    padding-right: 20px;
    min-width: 200px;
}
.link {
    color: #0d6efd;
    text-decoration: underline;
}
.link:hover {
    cursor: pointer;
}
.tableResults {
    padding-top: 20px;
}
button {
    margin-right: 10px;
}
.actionButtonRow {
    display: flex;
    flex-direction: row;
}
</style>
