<template>
    <!-- 
    Placeholder Page. For now anybody can land here.
    Its purpose for now is for dev team to be able to load/refresh metadata in their local databases.    
    -->
    <div>
        <button v-on:click="refreshMetadataClicked">REFRESH METADATA IN DATABASE</button>
        <button v-on:click="showMetadataClicked">SHOW METADATA FROM DATABASE</button>
        <div>{{ status }}</div>
        <div>{{ metadata }}</div>
    </div>
    
</template>

<script>
import axios from 'axios';

export default {
    name: "MetadataPage",

    data() {
        return {
            status: "",
            metadata: null
        }
    },

    methods: {
        async refreshMetadataClicked() {
            this.metadata = null;
            this.status = "Refreshing ...";
            await axios.put("/api/v1/admin/metadata", {})
            this.status = "Refreshed!!!";  
        },

        async showMetadataClicked() {
            this.status = "Loading ...";
            const response = await axios.get("/api/v1/burial/metadata", {});
            const { success, statusMessage } = response.data;
            if (success) {
                const { data } = response.data;
                this.metadata = data;
            }
            else {
                alert(statusMessage);
            }            
            this.status = "Loaded!!!";
        }
    }
}

</script>