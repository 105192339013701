<template>
<div>
<NavigationBar />
<div class="container-fluid py-4">
    <div class="card px-4" style="width: 100%;">
        <div class="card-header bg-primary text-white bg-gradient">
            <h3 class="py-3"><i class="bi bi-search"></i> Volunteer Search</h3>
        </div>
        <hr>
        <div v-if="!searching">
            <!-- Member Search Form -->
            <form @submit.prevent="search" @keydown="form.onKeydown($event)" class="form-horizontal">
                <!-- Error msg if no search fields provided -->
                <div v-if="msg.text" class="row mx-1">
                    <div class="alert" :class="msg.text && msg.type">
                        {{ msg.text }}
                    </div>
                </div>
                <div class="form-group row">
                <label for="jamatkhana" class="col-sm-2 col-form-label">
                    <h6>Jamatkhana:</h6>
                </label>
                <div class="col-sm-10">
                    <select class="form-select dropdown-input" id="jamatkhana" v-model="selectedJamatkhana">
                    <option :value="placeholderValue">Select an option (Required)</option>
                    <option v-for="jk in jamatkhanas" :key="jk.id" :value="jk.id">{{jk.name}}</option>
                    </select>
                </div>
                </div>
                <div class="form-group row">
                    <label for="phoneSearch" class="col-sm-2 col-form-label">
                        <h6>Phone:</h6>
                    </label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="phoneSearch" :class="{'is-invalid':form.errors.has('phone')}" v-model="form.phone">
                        <HasError :form="form" field="phone" />
                    </div>
                </div>
                <div class="form-group row">
                    <label for="emailSearch" class="col-sm-2 col-form-label">
                        <h6>Email:</h6>
                    </label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="emailSearch" :class="{'is-invalid':form.errors.has('email')}" v-model="form.email">
                        <HasError :form="form" field="email" />
                    </div>
                </div>
                <div class="form-group row">
                    <label for="firstnameSearch" class="col-sm-2 col-form-label">
                        <h6>First Name:</h6>
                    </label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="firstnameSearch" :class="{'is-invalid':form.errors.has('firstname')}" v-model="form.firstname">
                        <HasError :form="form" field="firstname" />
                    </div>
                </div>
                <div class="form-group row">
                    <label for="lastnameSearch" class="col-sm-2 col-form-label">
                        <h6>Last Name:</h6>
                    </label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="lastnameSearch" :class="{'is-invalid':form.errors.has('lastname')}" v-model="form.lastname">
                        <HasError :form="form" field="lastname" />
                    </div>
                </div>
                <div class="form-group row">
                    <label for="idSearch" class="col-sm-2 col-form-label">
                        <h6>Member Id:</h6>
                    </label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="idSearch" :class="{'is-invalid':form.errors.has('id')}" v-model="form.id">
                        <HasError :form="form" field="id" />
                    </div>
                </div>
                <Button :form="form" class="btn btn-primary mt-3">Search Member</Button>
            </form>
            <br>
        </div>
        <!-- Search Results -->
        <div v-if="searching && !error">
            <div v-if="loading">
                <LoadingSpinner />
                <!-- Replace with spinner once available -->
            </div>
            <div v-else>
                <div v-if="memData.data">Search Location: <b> {{getSearchScope(memData.searchLocation.jamatkhanaId, memData.searchLocation.regionId)}} </b></div>
                <!-- <h3>Location: {{ currentLocation?.name }}</h3> -->
                <!-- if data is null then display this -->
                <div v-if="memData.data && memData.data.length == 0">
                    <h3>No members found</h3>
                </div>
                <!-- if data then display data. Based on viewport width we display table or card-->
                <div v-else>
                    Showing {{firstRecord}}-{{lastRecord}} of {{totalRecords}} records
                    <hr>
                    <div v-if="cardOrTable">
                        <div class="container searchCard" v-for="member in memData.data" :key="member.memberId" @click.right.prevent @keydown="keydown" @copy.prevent @paste.prevent @click="goToVerify(member.memberId)">
                            <div class="row">
                                <div class="row d-flex float-end me-1">{{member.firstName + " " + member.lastName }}</div>
                                <div class="col row d-flex float-end">{{ (member.phone && member.phone.length > 0) ? member.phone[0].number : ""}}</div>
                                <div class="row d-flex float-end me-1">{{member.email}}</div>
                                <div class="col my-2">
                                    <div class="row d-flex float-end me-1">
                                        <button class="btn btn-primary" @click="goToAddVolunteer(member.memberId)" :disabled='currentVolunteerCheck(member.memberId)'>Grant Permissions</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Member Id</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Phone Number</th>
                                    <th>Email</th>
                                    <th>Jamatkhana</th>
                                    <th v-if="addVolunteerPermissionsCheck"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="table-row" v-for="member in memData.data" :key="member.memberId" @click.right.prevent @keydown="keydown" @copy.prevent @paste.prevent>
                                    <td> {{member.memberId }}</td>
                                    <td> {{member.firstName }}</td>
                                    <td> {{ member.lastName }}</td>
                                    <td v-if="member.phone && member.phone.length > 0">
                                        <div v-for="p in member.phone" :key="p.id">
                                            {{p.name + ": " + p.number}}
                                        </div>
                                    </td>
                                    <td v-else></td>
                                    <td> {{member.email}}</td>
                                    <td> {{ member.jamatkhana }}</td>
                                    <td v-if="addVolunteerPermissionsCheck">
                                        <button class="btn btn-primary" @click="goToAddVolunteer(member.memberId)">Grant Permissions</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex justify-content-center">
                        <PagerComponent :currentPageNo="currentPageNo" :lastPageNo="lastPageNo" @first-page="search(1)" @prev-page="search(currentPageNo - 1);" @next-page="search(currentPageNo + 1)" @last-page="search(lastPageNo)" :key="pageKey"></PagerComponent>
                    </div>
                </div>
                <br>
                <div class="py-2">
                    <div class="my-2">
                        <button class="btn btn-primary" @click="backSearch">Back to Search</button>
                    </div>
                    <div v-if="parentLocation.name" class="d-flex my-2">
                        <button class="btn btn-primary" @click="parentLocationSearch">Expand Search to {{parentLocation.name}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</template>

<script>
import axios from 'axios';
import {
    ref
} from 'vue';
import Form from 'vform';
import {
    Button,
    HasError
} from 'vform/src/components/bootstrap5';
import { getSearchScope, getVolunteerJks, placeholderValue } from '../utils/MethodUtils.js';
import NavigationBar from '../components/NavigationBar.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import PagerComponent from '../components/PagerComponent.vue';
export default {
    name: "VolunteerSearchPage",
    components: {
        NavigationBar,
        LoadingSpinner,
        Button,
        HasError,
        PagerComponent
    },

    setup() {
        const loading = ref(false);
        const searching = ref(false);
        const memData = ref([]);
        const msg = ref({});
        const error = ref(false);
        const permissions = ref()
        const cardOrTable = ref(false);
        const pageKey = ref([-1, -1]);
        const currentLocation = ref({
            id: "",
            name: ""
        });
        const parentLocation = ref({
            id: "",
            name: "Region"
        });
        const jkOptions = getVolunteerJks();
        const jamatkhanas = ref(jkOptions.jamatkhanas);
        const selectedJamatkhana = ref(jkOptions.selectedJamatkhana);

        return {
            getSearchScope,
            loading,
            searching,
            memData,
            error,
            permissions,
            cardOrTable,
            pageKey,
            msg,
            currentLocation,
            parentLocation,
            jamatkhanas,
            selectedJamatkhana,
            placeholderValue
        }
    },
    data: () => ({
        form: new Form({
            phone: "",
            email: "",
            firstname: "",
            lastname: "",
            id: "",
            locId: "",
            limitBy: "jamatkhana"
        }),
        window: {
            width: 0
        }
    }),
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.permissions = this.$store.getters.getVolunteerInfo;
        if (!this.permissions.resources.some(p => p.id === 2)) {
            this.$router.push('/search')
        }
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        // search method to retrieve data
        async search(pageNumber=1) {
            this.$store.commit('setSelectedMemberId', null);
            if (!this.selectedJamatkhana || this.selectedJamatkhana == placeholderValue) {
              this.msg.type = "alert-danger";
              this.msg.text = "Please select a Jamatkhana";
              return;
            }
            if (!(this.form.phone != "" || this.form.email != "" || (this.form.firstname != "" && this.form.lastname != "") || this.form.id != "")) {
                this.msg.type = "alert-danger"
                this.msg.text = "At least one field is required!"
                if ((this.form.firstname == "" && this.form.lastname != "") || (this.form.firstname != "" && this.form.lastname == "")) {
                    this.msg.text = "Both First and Last names are required"
                }
                return
            } else {
                this.searching = true
                this.loading = true
                const hasName = (this.form.firstname && this.form.lastname)
                try {
                    this.error = false
                    let params = {
                        memberId: this.form.id ? this.form.id : null,
                        firstName: hasName ? this.form.firstname : null,
                        lastName: hasName ? this.form.lastname : null,
                        phone: this.form.phone ? this.form.phone : null,
                        email: this.form.email ? this.form.email : null,
                        limitBy: this.form.limitBy,
                        page: pageNumber > 0 ? pageNumber: 1
                    }
                    if (this.form.limitBy === "jamatkhana") {
                      params.jamatkhanaId = this.selectedJamatkhana;
                    }

                    let {
                        data
                    } = await axios.get("/api/v1/iiplatform/search", {
                        params
                    });
                    if (data != null) {
                        if (!data.success) {
                            this.msg.type = "alert-danger"
                            this.msg.text = data.statusMessage
                            this.error = true
                            this.searching = false
                        } else {
                            this.memData = data
                            this.pageKey = [data.paginatorInfo.currentPage,data.paginatorInfo.lastPage];
                        }
                    }
                } catch (err) {
                    this.msg.type = "alert-danger"
                    this.msg.text = err;
                    this.error = true
                    this.searching = false
                }
                this.loading = false;
            }
        },
        backSearch() {
            this.msg.text = null
            this.form.limitBy = "jamatkhana"
            this.parentLocation.name = "Region"
            this.searching = false
        },
        parentLocationSearch() {
            if (this.form.limitBy == "region") {
                this.form.limitBy = null
                this.parentLocation.name = ""
            } else if (this.form.limitBy == "jamatkhana") {
                this.form.limitBy = "region"
                this.parentLocation.name = "National"

            }
            this.search()
        },
        handleResize() {
            this.window.width = window.innerWidth;
            if (this.window.width < 550) {
                this.cardOrTable = true
            } else {
                this.cardOrTable = false
            }
        },
        goToAddVolunteer(id) {
            this.$store.commit('setSelectedMemberId', id);
            this.$router.push("/volunteer/permissions");
        },
        addVolunteerPermissionsCheck() {
            if (this.permissions.resources.some(p => p.id === 2)) {
                return true
            } else {
                return false
            }
        }
    },
    computed: {
        currentPageNo: function () {
            return this.memData.paginatorInfo.currentPage
        },
        lastPageNo: function () {
            return this.memData.paginatorInfo.lastPage
        },
        firstRecord: function() {
            return this.memData.paginatorInfo.firstItem
        },
        lastRecord: function() {
            return this.memData.paginatorInfo.lastItem
        },
        totalRecords: function() {
            return this.memData.paginatorInfo.total
        },
    }
}
</script>

<style scoped>
.btn {
    font-weight: bold;
    color: white;
}

input.field {
    width: 50%
}

.searchCard {
    background-color: #ececec;
    margin: 10px 0px;
}

.table-row:hover,
.searchCard:hover {
    cursor: pointer;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
}
</style>
