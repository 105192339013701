<template>
    <div>
        <NavigationBar/>
        <h3> Oops! <br> Something went wrong :(</h3>
        <center>
            Please try again later.
        <br>
        <br>
        <a class="btn btn-success " href="/" > Back To Homepage </a>
        <br>
        <font size="1"><i>Message: {{errMsg}} </i></font>
        </center>
    </div>
</template>

<script>
import NavigationBar from '../components/NavigationBar.vue';
import { ref } from 'vue';

export default {
    name: "ErrorPage",
    components: {
        NavigationBar
    },
    props:['errorMsg'],
    setup(props) {
    const errMsg = ref('');

     const setErrMsg = async () => {
        errMsg.value = props.errorMsg;
     }

    setErrMsg();

     return {
        errMsg,
    }
    },
}
</script>
<style scoped>
  h3 {
    text-align: center;
    padding: 12px;
  }
</style>