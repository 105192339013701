<template>
<div>
    <NavigationBar />
    <div class="container">
        <h2>Set Terms and Mailing Instructions</h2>

        <div class="alert alert-danger alert-dismissible d-flex align-items-center fade show" v-if="error != ''">
            <i class="bi-exclamation-octagon-fill"></i>
            <strong class="mx-2">Error!</strong> {{ error }}
            <br />
        </div>
        <div class="alert alert-success alert-dismissible d-flex align-items-center fade show" v-if="successMessage != ''">
            <i class="bi-check-circle-fill"></i>
            <strong class="mx-2">Success!</strong> {{ successMessage }}
        </div>

        <div class="selectionOption">
            <label for="regionSelect">Region:</label>
            <select class="form-select" id="regionSelect" @change="regionChange(this.region)" v-model="region">
                <option value="-1" selected>Please select</option>
                <option v-for="region in regions" :value="region.id" :key="region.id">{{region.name}}</option>
            </select>
        </div>
        <div v-if="region !== '-1'" class="table-responsive">
            <table class="table table-striped w-auto">
                <thead>
                    <th>Location(JK)</th>
                    <th>Instructions/Terms and Conditions</th>
                </thead>
                <tbody>
                    <tr class="table-row" v-for="location in locations" :key="location.jamatkhana.id">
                        <td> {{ location.jamatkhana.name }}</td>
                        <td>
                            <label :for="location.jamatkhana.id + 'instructions'">
                                Mailing Instructions: 
                                <button 
                                    class="btn btn-link btn-sm" 
                                    data-bs-toggle="modal" 
                                    data-bs-target="#previewInstructionsModal"
                                    @click="clickPreview(location)">Preview
                                </button>
                            </label>
                            <textarea class="form-control instructionsTextArea" :id="location.jamatkhana.id + 'instructions'" rows="6" v-model="location.instructions"></textarea>
                            <label :for="location.id + 'terms'">URL for Terms and Conditions:</label>
                            <input class="form-control termsInput" :id="location.jamatkhana.id + 'terms'" type="url" v-model="location.terms">
                            <div class="selfRegistration">
                                <div class="registrationLabel">Set Self Registration Active: </div>
                                <div class="form-check form-switch registrationCheck">
                                    <input class="form-check-input" type="checkbox" v-model="location.active">
                                </div>
                            </div>
                        </td>
                        <td class="align-bottom">
                            <button 
                                class="btn btn-primary d-flex flex-row" 
                                :disabled="location.previousInstructions === location.instructions && location.previousTerms === location.terms && location.previousActive === location.active" 
                                @click="submitTOS(location)">Save
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="modal" id="previewInstructionsModal" tabindex="-1" aria-labelledby="previewInstructionsModal" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div v-if="selectedPreviewLocation" class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="previewInstructionsModalLabel">Preview Mailing Instructions - {{selectedPreviewLocation.jamatkhana.name}}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="card previewNote">
                                <div class="card-body">
                                    <b>Note:</b> Use the "&#123;&#123;paymentId&#125;&#125;" tag to reference the Jamati member's payment ID. When used correctly, this preview will replace it with a sample payment ID.
                                </div>
                            </div>
                            <div v-html="replacePaymentId('123456X2023X789', selectedPreviewLocation.instructions)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
import axios from 'axios';
import NavigationBar from '../components/NavigationBar.vue';
import { replacePaymentId } from '../utils/MethodUtils.js';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ref } from 'vue';

export default {
    name: "SetTerms",
    components: {
        NavigationBar,
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const regions = ref(store.getters.getMetadata.regions);
        const region = ref("-1");
        const locations = ref([]);
        const error = ref("");
        const successMessage = ref("");
        const selectedPreviewLocation = ref();

        let isInVolunteerFlow = store.getters.getIsInVolunteerFlow;
        if (isInVolunteerFlow === false) {
                router.push("/notfound");
        }

        const getTOSAndInstructions = (responses, id) => {
            const result = responses.filter(response => response.id === id);
            if (result.length === 0) {
                return {
                    id: id,
                    previousTerms: "",
                    previousInstructions: "",
                    previousActive: true
                }
            } else {
                return result[0]
            }
        }

        const regionChange = async (id) => {
            const jks = store.getters.getMetadata.jamatkhanas.filter(jk => jk.regionId === id);
            const responses = [];
            await Promise.all(jks.map(jk =>
                axios.get(`/api/v1/admin/instructions-and-tos/${jk.id}`).then(
                    response => {
                        const { success } = response.data;
                        if (success) {
                            const { data } = response.data;
                            responses.push({
                                id: jk.id,
                                previousTerms: data.termsAndConditionLink,
                                previousInstructions: data.instructions,
                                previousActive: data.selfRegistrationActive
                            })
                        }
                    })
            ))
            locations.value  = jks.map(jk => {
                const { previousTerms, previousInstructions, previousActive } = getTOSAndInstructions(responses, jk.id);
                return {
                        jamatkhana: {
                            name: jk.name,
                            id: jk.id,
                            regionId: jk.regionId
                        },
                        previousInstructions: previousInstructions,
                        previousTerms: previousTerms,
                        previousActive: previousActive,
                        instructions: previousInstructions,
                        terms: previousTerms,
                        active: previousActive,
                }
            })
        }

        const submitTOS = async (location) => {
            error.value = "";
            successMessage.value = "";
            const payload = {
                jamatkhanaIds: [location.jamatkhana.id],
                instructions: location.instructions,
                termsAndConditionLink: location.terms,
                selfRegistrationActive: location.active
            }
            try {
                const response = await axios.post('/api/v1/admin/instructions-and-tos', payload);
                const { success, statusMessage } = response.data;
                if (success) {
                    successMessage.value = `${location.jamatkhana.name} was succesfully saved`
                    let locationsCopy = locations.value;
                    let foundIndex = locationsCopy.findIndex(loc => location.jamatkhana.id === loc.jamatkhana.id);
                    locationsCopy[foundIndex] = {...location, previousInstructions: location.instructions, previousTerms: location.terms, previousActive: location.active};
                    locations.value = locationsCopy;
                } else {
                    error.value = statusMessage;
                    console.log(statusMessage);
                }
            } catch (err) {
                error.value = err;
                console.error(err);
            }
        }

        const clickPreview = (loc) => {
            selectedPreviewLocation.value = loc;
        }

        return {
            clickPreview,
            regionChange,
            replacePaymentId,
            submitTOS,
            locations,
            selectedPreviewLocation,
            regions,
            region,
            successMessage,
            error
        }
    },
    async created() {
        let volunteer = this.$store.getters.getVolunteerInfo;
        this.regions = volunteer.regions;
        if (!volunteer.resources.some(permission => permission.id === 6)) {
            this.$router.push("/search");
        }
    }
}
</script>

<style scoped>
.previewNote {
    margin-bottom: 20px;
}
.selectionOption {
    max-width: 400px;
    padding-bottom: 20px;
}
.instructionsTextArea {
    width: 700px;
}
.selfRegistration {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.registrationLabel {
    padding-right: 10px;
}
.registrationCheck {
    padding-top: 2px;
}
</style>
