<template>
    <div>
        <NavigationBar/>
        <h3>OOPS!</h3>
        <center>
        <h5>Error 404: Page Not Found </h5>
        <br>
        <a class="btn btn-success " href="/" > Back To Homepage </a>
        </center>
    </div>
</template>

<script>
import NavigationBar from '../components/NavigationBar.vue';

export default {
    name: "NotFoundPage",
    components: {
        NavigationBar
    },
    setup() {

     return {

     }
    },
}
</script>
<style scoped>
  h3 {
    text-align: center;
    padding: 12px;
    font-size: xx-large;

  }
</style>