<template>
    <div>
        <h4 class="pt-3 text-center">Covered Members</h4>
        <hr class="smaller-hr">
        <div v-if="showCardView">
            <div class="container memberCard" :class="paidCardClass(familyMember)"  v-for="familyMember in membersData || []" :key="familyMember.id" @click.right.prevent @keydown="keydown" @copy.prevent @paste.prevent>
                <div class="row">
                    <div class="col" :class="paidTextClass(familyMember)">{{familyMember.firstName + " " + familyMember.lastName }} </div>
                    <div class="col"> <div class="row d-flex float-end me-1" :class="paidTextClass(familyMember)">{{familyMember.relationToHead.name}}</div></div>
                </div>
                <div class="row">
                    <div class="col" :class="paidTextClass(familyMember)">{{ familyMember.rate.amount != null ? displayInUSD(familyMember.rate.amount) : familyMember.rate.info}}</div>
                    <div class="col">
                        <div class="d-flex float-end justify-content-end">
                            <div v-if="familyMember.status.id !== 1">
                                <i class="material-icons green">attach_money</i>
                            </div>
                            <div v-else-if="familyMember.rate.amount === null" />
                            <div v-else class="form-check form-switch d-flex align-items-start">
                                <input class="form-check-input " type="checkbox" v-model="familyMember.includeInPlan">
                            </div>
                            <h6 class="mx-2" v-if="familyMember.rate.amount !== null"> Include </h6>  
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-container">
                <div class="input-item">
                    <div class="summary-row">
                        <div class="summary-label"><b>Subtotal:</b></div>
                        <div class="summary-input">{{displayInUSD(subtotalAmount)}}</div>
                    </div>
                </div>
                <div class="input-item">
                    <div class="summary-row">
                        <div class="summary-label"><b>Donation:</b></div>
                        <div class="summary-input">
                            <CurrencyInput 
                                @input="event => setDonationAmount(event.target.value)" 
                                :value="donationInputField" 
                                :options="{ currency: 'USD', precision: 2, valueRange: {min: 0}}" 
                            />
                        </div>
                    </div>
                </div>
                <div class="input-item">
                    <div class="summary-row">
                        <div class="summary-label"><b>Total:</b></div>
                        <div class="summary-input">{{displayInUSD(totalAmount)}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="table-responsive household-Member-Table pt-2">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Relationship</th>
                            <th>Included</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr :class="paidRowClass(familyMember)" v-for="familyMember in membersData || []" :key="familyMember.id"  @click.right.prevent @keydown="keydown" @copy.prevent @paste.prevent>
                            <td :class="paidTextClass(familyMember)"> {{ familyMember.firstName }} {{ familyMember.lastName }}</td>
                            <td :class="paidTextClass(familyMember)"> {{ familyMember.relationToHead.name}}</td>
                            <td class="text-center">
                                <div v-if="familyMember.status.id !== 1">
                                    <i class="material-icons green">attach_money</i>
                                </div>
                                <div v-else-if="familyMember.rate.amount === null" />
                                <div v-else class="form-check form-switch form-control-lg">
                                    <input class="form-check-input " type="checkbox" v-model="familyMember.includeInPlan">
                                </div>
                            </td>
                            <td :class="paidTextClass(familyMember)">{{ familyMember.rate.amount != null ? displayInUSD(familyMember.rate.amount): familyMember.rate.info }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td></td>
                            <td style="text-align:right"><b>Subtotal:</b></td>
                            <td>{{displayInUSD(subtotalAmount)}}</td>
                        </tr>
                    <tr class="noBorder">
                        <td></td>
                        <td colspan="2" style="text-align:right"><b>Donation:</b></td>
                        <td>
                            <CurrencyInput 
                                @input="event => setDonationAmount(event.target.value)" 
                                :value="donationInputField" 
                                :options="{ currency: 'USD', precision: 2, valueRange: {min: 0}}" 
                            />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td colspan="2" style="text-align:right"><b>Total:</b></td>
                        <td><b>{{displayInUSD(totalAmount)}}</b></td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue'
import CurrencyInput from '../components/CurrencyInput.vue';
import { displayInUSD } from '../utils/MethodUtils.js'
export default {
    props: [
        "membersData",
        "subtotalAmount", 
        "totalAmount", 
        "donationInputField",
        "setDonationAmount"
    ],
    name: "PaymentTable",
    components: {
        CurrencyInput
    },
    setup() {
        const showCardView=ref(false);
        return {
            showCardView,
            displayInUSD
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    },
    data: () => ({
        window:{
            width: 0
        }
    }),
    methods:{
        handleResize() {
            this.window.width = window.innerWidth;
            if (this.window.width < 500) {
                this.showCardView=true
            } else {
                this.showCardView=false
            }
        },
        paidRowClass: (familyMember) => {
            if (familyMember.status.id !== 1) {
                return { 'table-success': true}
            } else if (familyMember.rate.amount === null) {
                return { 'table-danger': true}
            } else {
                return { 'table-secondary': !familyMember.includeInPlan }
            }
        },
        paidCardClass: (familyMember) => {
            if (familyMember.status.id !== 1) {
                return { 'paid-card': true}
            } else if (familyMember.rate.amount === null) {
                return { 'invalid-card': true }
            }else {
                return { 'unincluded-card': !familyMember.includeInPlan }
            }
        },
        paidTextClass: (familyMember) => {
            if (familyMember.status.id === 1 && familyMember.rate.amount !== null) {
                return { 'excluded': !familyMember.includeInPlan }
            }
        }
    }
}
</script>
<style scoped>
    .paid-card {
        background-color: #d1e7dd;
    }
    .invalid-card{
        background-color: #f8d7da;
    }
    .unincluded-card {
        background-color: #e2e3e5;
    }
    .form-check-input:checked {
        background-color: green;
    }
    .include-input {
        border: 1px red solid;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .memberCard{
        border-bottom: 1px solid #ccc;
        padding: 4px;
    }
    .material-icons.green { 
        color: green; 
    }
    .smaller-hr{
        margin: 0.15rem auto;
    }
    tr.noBorder td {
        border-style:hidden;
    }
    .excluded {
        text-decoration:line-through;
    }
    .testing-this {
        border: 1px blue solid;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .footer-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #ccc;
        margin-bottom: 12px;
        padding-top: 12px;
    }
    .footer-inputs {
        max-width: 350px;
    }
    .summary-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 12px;
    }
    .summary-label {
        white-space: nowrap;
        padding-right: 12px;
    }
    @media(min-width: 401px) {
        .summary-input {
            min-width: 200px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
    @media(max-width: 400px) {
        .summary-input {
            max-width: 100px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number]{
        -moz-appearance: textfield;
    }       
</style>
