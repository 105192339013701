<template>
<NavigationBar />
<div class="container">
    <div v-if="!loading">
        <div class="alert alert-danger alert-dismissible d-flex align-items-center fade show" v-if="error != ''">
            <br />
            <i class="bi-exclamation-octagon-fill"></i>
            <strong class="mx-2">Error!</strong> {{ error }}
            <br />
        </div>
        <h3 class="my-3">{{ targetVolunteer.volunteer.name }}</h3>
        <br />
        <button class="btn btn-outline-danger float-end" @click="removeVolunteer">Remove Volunteer</button>
        <br />
        <h3 class="my-2">Grant Access</h3>
        <br />
        <div v-if="regionalOrLocalPermissions">
            <label class="form-select-label" for="regionSelect">Please Select Region or Jamatkhana Access</label>
            <select class="form-select" aria-label="Default select example" v-model="accessLevel" v-if="regionalOrLocalPermissions" @change="clearValues">
                <option selected>Select Access Level</option>
                <option value="1">Regional</option>
                <option value="2">Jamatkhana</option>
            </select>
        </div>
        <div v-if="regionalOrLocalPermissions == false">
            <br />
            <h5>Jamatkhana Access</h5>
            <br />
            <select class="form-select" v-model="jamatkhanasAssigned">
                <option v-for="jk in volunteer.jamatkhanas" :key="jk.id" :value="jk.id" selected="jamatkhanasAssigned">
                    {{ jk.name }}
                </option>
            </select>
            <br />
        </div>
        <div v-if="accessLevel == '2' && regionalOrLocalPermissions">
            <br />
            <label class="form-select-label" for="regionSelect">Please Select a Region</label>
            <select class="form-select" aria-label="Default select example" v-model="regionSelect" id="regionSelect">
                <option v-for="region in volunteer.regions" :key="region.id" :value="region.id">{{region.name}}</option>
            </select>
            <br />
            <div v-if="regionSelect">
                <h5>Jamatkhana Access</h5>
                <br />
                <select class="form-select" v-model="jamatkhanasAssigned">
                    <option v-for="jk in volunteer.regions.filter(function (region) {return region.id == regionSelect})[0].jamatkhanas" :key="jk.id" :value="jk.id" selected="jamatkhanasAssigned">
                        {{ jk.name }}
                    </option>
                </select>
                <br />
            </div>
        </div>
        <div v-if="accessLevel == '1'">
            <br />
            <h5>Region Access</h5>
            <br />
            <select class="form-select" v-model="regionsAssigned">
                <option v-for="region in volunteer.regions" :key="region.id" :value="region.id" selected="regionsAssigned">
                    {{ region.name }}
                </option>
            </select>
            <br />
        </div>
        <div v-if="regionsAssigned || jamatkhanasAssigned" class="my-4">
            <br />
            Assign Permissions<br/>
            <br />
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="{{region.name}}CheckBox" v-model="grantVolunteer">
                <label class="form-check-label" for="">{{ volunteer.resources.filter(r => r.id == 1)[0].description }}</label>
            </div>
            <div class="form-check form-switch" v-if="grantVolunteer">
                <input class="form-check-input" type="checkbox" id="{{region.name}}CheckBox" v-model="grantAddVolunteer">
                <label class="form-check-label" for="">{{ volunteer.resources.filter(r => r.id == 2)[0].description }}</label>
            </div>
            <div class="form-check form-switch" v-if="grantVolunteer && reportPermissionCheck">
                <input class="form-check-input" type="checkbox" id="{{region.name}}CheckBox" v-model="grantRunReports">
                <label class="form-check-label" for="">{{ volunteer.resources.filter(r => r.id == 3)[0].description }}</label>
            </div>
            <div class="form-check form-switch" v-if="grantVolunteer && pendingPaymentReportCheck">
                <input class="form-check-input" type="checkbox" id="{{region.name}}CheckBox" v-model="grantPendingPaymentsReport">
                <label class="form-check-label" for="">{{ volunteer.resources.filter(r => r.id == 4)[0].description }}</label>
            </div>
            <div class="form-check form-switch" v-if="grantVolunteer && setPricesPermissionCheck && accessLevel == '1'">
                <input class="form-check-input" type="checkbox" id="{{region.name}}CheckBox" v-model="grantSetPrices">
                <label class="form-check-label" for="">{{ volunteer.resources.filter(r => r.id == 5)[0].description }}</label>
            </div>
            <div class="form-check form-switch" v-if="grantVolunteer && setTermsMailPermissionCheck && accessLevel == '1'">
                <input class="form-check-input" type="checkbox" id="{{region.name}}CheckBox" v-model="grantSetTermsMail">
                <label class="form-check-label" for="">{{ volunteer.resources.filter(r => r.id == 6)[0].description }}</label>
            </div>
            <div class="form-check form-switch" v-if="grantVolunteer && priceAdjustmentsCheck && accessLevel == '1'">
                <input class="form-check-input" type="checkbox" id="{{region.name}}CheckBox" v-model="grantPriceAdjustments">
                <label class="form-check-label" for="">{{ volunteer.resources.filter(r => r.id == 7)[0].description }}</label>
            </div>
            <div class="form-check form-switch" v-if="grantVolunteer && processingPaymentReportCheck && accessLevel == '1'">
                <input class="form-check-input" type="checkbox" id="{{region.name}}CheckBox" v-model="grantProcessingPaymentReport">
                <label class="form-check-label" for="">{{ volunteer.resources.filter(r => r.id == 8)[0].description }}</label>
            </div>
            <div class="form-check form-switch" v-if="grantVolunteer && createBatchesCheck && accessLevel == '1'">
                <input class="form-check-input" type="checkbox" id="{{region.name}}CheckBox" v-model="grantCreateBatches">
                <label class="form-check-label" for="">{{ volunteer.resources.filter(r => r.id == 9)[0].description }}</label>
            </div>
            <div class="form-check form-switch" v-if="grantVolunteer && viewBatchesCheck && accessLevel == '1'">
                <input class="form-check-input" type="checkbox" id="{{region.name}}CheckBox" v-model="grantViewBatches">
                <label class="form-check-label" for="">{{ volunteer.resources.filter(r => r.id == 10)[0].description }}</label>
            </div>
            <div class="form-check form-switch" v-if="grantVolunteer && markAchReturnedCheck && accessLevel == '1'">
                <input class="form-check-input" type="checkbox" id="{{region.name}}CheckBox" v-model="grantMarkAchReturned">
                <label class="form-check-label" for="">{{ volunteer.resources.filter(r => r.id == 11)[0].description }}</label>
            </div>
            <div class="my-4" v-if="grantVolunteer">
                <button class="btn btn-primary" @click="editVolunteer">Save</button>
            </div>
        </div>
    </div>
    <div v-else>
        <LoadingSpinner />
    </div>
</div>
</template>

<script>
import axios from 'axios';
import {
    ref
} from 'vue';
import NavigationBar from '../components/NavigationBar.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue'

export default {
    name: "HomePage",
    components: {
        NavigationBar,
        LoadingSpinner,
    },

    setup() {
        const loading = ref(true)
        const memberId = ref(0);
        const regionalOrLocalPermissions = ref(false)
        const accessLevel = ref("")
        const regionsAssigned = ref()
        const jamatkhanasAssigned = ref()
        const regionSelect = ref(0)
        const grantVolunteer = ref(false)
        const grantAddVolunteer = ref(false)
        const grantRunReports = ref(false)
        const grantPendingPaymentsReport = ref(false)
        const grantSetPrices = ref(false)
        const grantSetTermsMail = ref(false)
        const grantPriceAdjustments = ref(false)
        const grantProcessingPaymentReport = ref(false)
        const grantCreateBatches = ref(false)
        const grantViewBatches = ref(false)
        const grantMarkAchReturned = ref(false)

        const reportPermissionCheck = ref(false)
        const pendingPaymentReportCheck = ref(false)
        const setPricesPermissionCheck = ref(false)
        const setTermsMailPermissionCheck = ref(false)
        const priceAdjustmentsCheck = ref(false)
        const processingPaymentReportCheck = ref(false)
        const createBatchesCheck = ref(false)
        const viewBatchesCheck = ref(false)
        const markAchReturnedCheck = ref(false)

        const error = ref("")
        const volunteer = ref()
        const targetVolunteer = ref({})
        const jamatkhanasToRegionIds = ref()

        const getTargetVolunteer = async (id) => {
            try {
                const response = await axios.get("/api/v1/admin/volunteer/" + id);
                const {
                    success,
                    statusMessage
                } = response.data;
                if (success) {
                    const {
                        data
                    } = response.data;
                    targetVolunteer.value = data
                } else {
                    console.log(statusMessage)
                }
            } catch (err) {
                console.log(err)
            }
        }

        return {
            loading,
            memberId,
            accessLevel,
            regionalOrLocalPermissions,
            jamatkhanasAssigned,
            regionsAssigned,
            regionSelect,
            grantVolunteer,
            grantAddVolunteer,
            grantRunReports,
            grantPendingPaymentsReport,
            grantSetPrices,
            grantSetTermsMail,
            grantPriceAdjustments,
            grantProcessingPaymentReport,
            grantCreateBatches,
            grantViewBatches,
            grantMarkAchReturned,
            reportPermissionCheck,
            pendingPaymentReportCheck,
            setPricesPermissionCheck,
            setTermsMailPermissionCheck,
            priceAdjustmentsCheck,
            processingPaymentReportCheck,
            createBatchesCheck,
            viewBatchesCheck,
            markAchReturnedCheck,
            error,
            volunteer,
            targetVolunteer,
            jamatkhanasToRegionIds,
            getTargetVolunteer,
        }
    },
    async created() {
        this.memberId = this.$store.getters.getSelectedMemberId;
        const metadata = this.$store.getters.getMetadata
        this.jamatkhanasToRegionIds = metadata.jamatkhanas
        await this.getTargetVolunteer(this.memberId)
        this.volunteer = this.$store.getters.getVolunteerInfo;
        if (!this.volunteer.resources.some(permission => permission.id === 2)) {
            this.$router.push("/search");
        }
        if (this.volunteer.resources.some(permission => permission.id === 3)) {
            this.reportPermissionCheck = true
        }
        if (this.volunteer.resources.some(permission => permission.id === 4)) {
            this.pendingPaymentReportCheck = true
        }
        if (this.volunteer.resources.some(permission => permission.id === 5)) {
            this.setPricesPermissionCheck = true
        }
        if (this.volunteer.resources.some(permission => permission.id === 6)) {
            this.setTermsMailPermissionCheck = true
        }
        if (this.volunteer.resources.some(permission => permission.id === 7)) {
            this.priceAdjustmentsCheck = true
        }
        if (this.volunteer.resources.some(permission => permission.id === 8)) {
            this.processingPaymentReportCheck = true
        }
        if (this.volunteer.resources.some(permission => permission.id === 9)) {
            this.createBatchesCheck = true
        }
        if (this.volunteer.resources.some(permission => permission.id === 10)) {
            this.viewBatchesCheck = true
        }
        if (this.volunteer.resources.some(permission => permission.id === 11)) {
            this.markAchReturnedCheck = true
        }
        if (this.volunteer.regions) {
            this.regionalOrLocalPermissions = true
        }
        if (!this.permissionCheck()) {
            this.$router.push("/volunteer/management");
        }
        this.populateDefaultValues()
        this.loading = false;
    },
    methods: {
        clearValues() {
            this.regionSelect = 0;
            this.regionsAssigned = null;
            this.jamatkhanasAssigned = null;
        },
        resourceChecker() {
            let resources = [1]
            if (this.grantAddVolunteer == true) {
                resources.push(2)
            }
            if (this.grantRunReports == true) {
                resources.push(3)
            }
            if (this.grantPendingPaymentsReport == true) {
                resources.push(4)
            }
            if (this.grantSetPrices == true) {
                resources.push(5)
            }
            if (this.grantSetTermsMail == true) {
                resources.push(6)
            }
            if (this.grantPriceAdjustments == true) {
                resources.push(7)
            }
            if (this.grantProcessingPaymentReport == true) {
                resources.push(8)
            }
            if (this.grantCreateBatches == true) {
                resources.push(9)
            }
            if (this.grantViewBatches == true) {
                resources.push(10)
            }
            if (this.grantMarkAchReturned == true) {
                resources.push(11)
            }
            return resources
        },
        populateDefaultValues() {
            if (this.targetVolunteer.regions) {
                this.accessLevel = 1
                this.regionsAssigned = this.targetVolunteer.regions[0].id
            }
            if (this.targetVolunteer.jamatkhanas) {
                this.accessLevel = 2
                let jkId = this.targetVolunteer.jamatkhanas[0].id
                this.regionSelect = this.jamatkhanasToRegionIds.find(
                    (jk) => jk.id === jkId).regionId
                this.jamatkhanasAssigned = jkId
            }
            if (this.targetVolunteer.resources.some(permission => permission.id === 1)) {
                this.grantVolunteer = true
            }
            if (this.targetVolunteer.resources.some(permission => permission.id === 2)) {
                this.grantAddVolunteer = true
            }
            if (this.targetVolunteer.resources.some(permission => permission.id === 3)) {
                this.grantRunReports = true
            }
            if (this.targetVolunteer.resources.some(permission => permission.id === 4)) {
                this.grantPendingPaymentsReport = true
            }
            if (this.targetVolunteer.resources.some(permission => permission.id === 5)) {
                this.grantSetPrices = true
            }
            if (this.targetVolunteer.resources.some(permission => permission.id === 6)) {
                this.grantSetTermsMail = true
            }
            if (this.targetVolunteer.resources.some(permission => permission.id === 7)) {
                this.grantPriceAdjustments = true
            }
            if (this.targetVolunteer.resources.some(permission => permission.id === 8)) {
                this.grantProcessingPaymentReport = true
            }
            if (this.targetVolunteer.resources.some(permission => permission.id === 9)) {
                this.grantCreateBatches = true
            }
            if (this.targetVolunteer.resources.some(permission => permission.id === 10)) {
                this.grantViewBatches = true
            }
            if (this.targetVolunteer.resources.some(permission => permission.id === 11)) {
                this.grantMarkAchReturned = true
            }
        },
        async editVolunteer() {
            this.error = ""
            this.loading = true;
            try {
                let response = await axios.put('/api/v1/admin/volunteer', {
                    volunteerId: this.memberId,
                    regionIds: this.regionsAssigned ? [this.regionsAssigned] : null,
                    jamatkhanaIds: this.jamatkhanasAssigned ? [this.jamatkhanasAssigned] : null,
                    resourceIds: this.resourceChecker()
                });
                let {
                    success,
                    statusMessage
                } = response.data;
                if (!success) {
                    this.error = statusMessage;
                    this.loading = false;
                    return
                }
            } catch (err) {
                console.error(err);
                this.error = err;
                this.loading = false;
                return
            }
            this.$router.push('/volunteer/management')
        },
        async removeVolunteer() {
            this.error = ""
            this.loading = true;
            try {
                let response = await axios.delete('/api/v1/admin/volunteer/' + this.memberId)
                let {
                    success,
                    statusMessage
                } = response.data
                if (!success) {
                    this.error = statusMessage;
                    this.loading = false;
                    return
                }
            } catch (err) {
                console.error(err)
                this.error = err;
                this.loading = false;
                return;
            }
            this.$router.push('/volunteer/management')
        },
        permissionCheck() {
            let checker = (arr, target) => target.every(el => arr.some(elem => elem.id === el.id));
            if (this.targetVolunteer.regions) {
                if (!checker(this.volunteer.regions, this.targetVolunteer.regions)) {
                    return false
                }
            }
            if (this.targetVolunteer.regions && !this.volunteer.regions) {
                return false
            }
            if (this.targetVolunteer.jamatkhanas && !this.volunteer.regions) {
                if (!checker(this.volunteer.jamatkhanas, this.targetVolunteer.jamatkhanas)) {
                    return false
                }
            }
            if (!checker(this.volunteer.resources, this.targetVolunteer.resources)) {
                return false
            }
            return true
        },
    },
}
</script>

<style scoped>
</style>
