<template>
  <div v-if="hasPages" class="btn-toolbar" role="toolbar">
    <div class="btn-group" role="group">
      <button class="btn btn-primary btn-sm" :disabled="currentPageNum <= 1" @click="firstPage"><i class="material-icons mt-1 md-18">skip_previous</i></button>
      <button class="btn btn-primary btn-sm" :disabled="currentPageNum <= 1" @click="prevPage"><i class="material-icons mt-1 md-18">fast_rewind</i></button>
    </div>
    <div class="my-auto mx-3">
      <span>Page {{ currentPageNum }} of {{ lastPageNum }}</span>
    </div>
    <div class="btn-group" role="group">
      <button class="btn btn-primary btn-sm" :disabled="currentPageNum >= lastPageNum" @click="nextPage"><i class="material-icons mt-1 md-18">fast_forward</i></button>
      <button class="btn btn-primary btn-sm" :disabled="currentPageNum >= lastPageNum" @click="lastPage"><i class="material-icons mt-1 md-18">skip_next</i></button>
    </div>
  </div>
</template>
<script>
import { ref, computed } from 'vue'
export default {
  props:['currentPageNo','lastPageNo'],
  emits:['first-page','prev-page','next-page','last-page'],
  name: "PagerComponent",
  setup(props, { emit }){
    const currentPageNum = ref(props.currentPageNo)
    const lastPageNum = ref(props.lastPageNo)
    const hasPages = computed(() => lastPageNum.value > 1)
    const firstPage = () => emit('first-page')
    const prevPage = () => emit('prev-page')
    const nextPage = () => emit('next-page')
    const lastPage = () => emit('last-page')
    return {
      currentPageNum,
      lastPageNum,
      hasPages,
      firstPage,
      prevPage,
      nextPage,
      lastPage,
    }
  }
}
</script>
<style scoped>
</style>
