<template>
    <div>
        <NavigationBar/>
        <div v-if="loaded">
            <h3>Welcome {{ volunteerName ? volunteerName : '' }}!</h3>
            <div class="row">
                <div class="form-group offset-sm-2 col-sm-8">
                <button class="btn btn-success w-100" @click="setIsInVolunteerFlow(false)">Proceed as Jamati Member</button>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="form-group offset-sm-2 col-sm-8">
                <button class="btn btn-primary w-100" @click="setIsInVolunteerFlow(true)">Proceed as Volunteer</button>
                </div>
            </div>
        </div>
        <div v-else>
            <LoadingSpinner />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import NavigationBar from '../components/NavigationBar.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue'

export default {
    name: "HomePage",
    components: {
        NavigationBar,
        LoadingSpinner
    },

    setup() {
        const store = useStore();
        const router = useRouter();
        const loaded = ref(false);
        const volunteerName = ref('');

        const setIsInVolunteerFlow = (value) => {
            store.commit('setIsInVolunteerFlow', value);
            routeJamatiOrVolunteerFlow();
        }

        const routeJamatiOrVolunteerFlow = () => {
            let isInVolunteerFlow = store.getters.getIsInVolunteerFlow;
            if (isInVolunteerFlow === false) {
                router.push("/userInfo");
            } else if (isInVolunteerFlow === null) {
              // TODO(alisoomar) replace with `true` when the backend actually supports
              // setting volunteer state
                // Make call to check permissions (is volunteer or not)
                // If not volunteer, set isInVolunteerFlow to false and go to Jamati flow
            }
            // If true, go to volunteer flow
            else if(isInVolunteerFlow === true)
            {
                router.push({name: 'Search'});
            }
        }

        const getSubject = async () => {
            try
            {
                loaded.value = false;
                let localSubject = store.getters.getSubject;
                let subject = store.getters.getSubject;
                if (subject === null) {
                    const { data } = await axios.get("/api/auth/subject");
                    if (data.success)
                    {
                        localSubject = data.data;
                    }
                }
                if (localSubject === null) {
                    router.push("/login");
                } else {
                    store.commit('setSubject', localSubject);
                }
                let userInfo = store.getters.getSubject;
                if(userInfo != null)
                {
                    await getVolunteerStatus(userInfo.memberId);
                }
                let volunteerInfo = store.getters.getVolunteerInfo;
                if(volunteerInfo && volunteerInfo.volunteer)
                {
                    volunteerName.value = volunteerInfo.volunteer.name
                }
                await getMetadata();
                routeJamatiOrVolunteerFlow();
                loaded.value = true;
            }
            catch(err)
            {
                console.log(err);
                router.push({
                    name: 'Error',
                    params: {
                        errorMsg: "Home - " + err,
                    }
                });
            }
        }

        const getVolunteerStatus = async (id) => {
            try {
                let localVolunteerInfo = null;
                let volunteerInfo = store.getters.getVolunteerInfo;
                if (volunteerInfo === null) {
                    const { data } = await axios.get("/api/v1/admin/volunteer/" + id);
                    if (data.success) {
                        localVolunteerInfo = data.data;
                    }
                }
                if (localVolunteerInfo !== null) {
                    store.commit('setVolunteerInfo', localVolunteerInfo)
                }
                let volunteerStatus = store.getters.getVolunteerInfo;
                if (volunteerStatus.isVolunteer != true || volunteerStatus.isActive != true) {
                    store.commit('setIsInVolunteerFlow', false);
                    router.push("/userInfo");
                }
            } catch (err) {
                console.log(err);
                router.push({
                    name: 'Error',
                    params: {
                        errorMsg: "Home - " + err,
                    }
                })
            }
        }

        const getMetadata = async () => {
            try {
                const response = await axios.get("/api/v1/burial/metadata");
                const { success, statusMessage } = response.data;
                if (success) {
                    const { data } = response.data;
                    store.commit('setMetadata', data);
                }
                else {
                    console.log(statusMessage);
                    router.push({
                        name: 'Error',
                        params: {
                            errorMsg: "Home - " + statusMessage,
                        }
                    })
                }        
            } catch (err) {
                console.log(err);
                router.push({
                    name: 'Error',
                    params: {
                        errorMsg: "Home - " + err,
                    }
                })
            }
        }

        onMounted(() => {
            getSubject()
        });

        return {
            setIsInVolunteerFlow, 
            routeJamatiOrVolunteerFlow,
            loaded,
            volunteerName,
        }
    },

    data() {
        return {
        address: null,
        familyMembers: []
        };
    },
}
</script>
<style scoped>
  h3 {
    text-align: center;
    padding: 12px;
  }
</style>