<template>
<NavigationBar />
<div class="container">
    <div v-if="!loading">
        <div class="alert alert-danger alert-dismissible d-flex align-items-center fade show" v-if="error != ''">
            <br />
            <i class="bi-exclamation-octagon-fill"></i>
            <strong class="mx-2">Error!</strong> {{ error }}
            <br />
        </div>
        <br />
        <h3 class="my-3">{{ volunteerName }}</h3>
        <br />
        <h3 class="my-2">Grant Access</h3>
        <br />
        <div v-if="regionalOrLocalPermissions">
            <label class="form-select-label" for="regionSelect">Please Select Region or Jamatkhana Access</label>
            <select class="form-select" aria-label="Default select example" v-model="accessLevel" v-if="regionalOrLocalPermissions" @change="clearValues">
                <option selected>Select Access Level</option>
                <option value="1">Region</option>
                <option value="2">Jamatkhana</option>
            </select>
        </div>
        <div v-if="regionalOrLocalPermissions == false">
            <br />
            <h5>Jamatkhana Access</h5>
            <br />
            <select class="form-select" v-model="jamatkhanasAssigned">
                <option v-for="jk in volunteer.jamatkhanas" :key="jk.id" :value="jk.id" selected="jamatkhanasAssigned">
                    {{ jk.name }}
                </option>
            </select>
            <br />
        </div>
        <div v-if="accessLevel == '2' && regionalOrLocalPermissions">
            <br />
            <label class="form-select-label" for="regionSelect">Please Select a Region</label>
            <select class="form-select" aria-label="Default select example" v-model="regionSelect" id="regionSelect">
                <option v-for="region in volunteer.regions" :key="region.id" :value="region.id">{{region.name}}</option>
            </select>
            <br />
            <div v-if="regionSelect">
                <h5>Jamatkhana Access</h5>
                <br />
                <select class="form-select" v-model="jamatkhanasAssigned">
                    <option v-for="jk in volunteer.regions.filter(function (region) {return region.id == regionSelect})[0].jamatkhanas" :key="jk.id" :value="jk.id" selected="jamatkhanasAssigned">
                        {{ jk.name }}
                    </option>
                </select>
                <br />
            </div>
        </div>
        <div v-if="accessLevel == '1'">
            <br />
            <h5>Region Access</h5>
            <br />
            <select class="form-select" v-model="regionsAssigned">
                <option v-for="region in volunteer.regions" :key="region.id" :value="region.id" selected="regionsAssigned">
                    {{ region.name }}
                </option>
            </select>
            <br />
        </div>
        <div v-if="regionsAssigned || jamatkhanasAssigned" class="my-4">
            <br />
            Assign Permissions<br />
            <br />
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="{{region.name}}CheckBox" v-model="grantVolunteer">
                <label class="form-check-label" for="">{{ volunteer.resources.filter(r => r.id == 1)[0].description }}</label>
            </div>
            <div class="form-check form-switch" v-if="grantVolunteer">
                <input class="form-check-input" type="checkbox" id="{{region.name}}CheckBox" v-model="grantAddVolunteer">
                <label class="form-check-label" for="">{{ volunteer.resources.filter(r => r.id == 2)[0].description }}</label>
            </div>
            <div class="form-check form-switch" v-if="grantVolunteer && reportPermissionCheck">
                <input class="form-check-input" type="checkbox" id="{{region.name}}CheckBox" v-model="grantRunReports">
                <label class="form-check-label" for="">{{ volunteer.resources.filter(r => r.id == 3)[0].description }}</label>
            </div>
            <div class="form-check form-switch" v-if="grantVolunteer && pendingPaymentReportCheck">
                <input class="form-check-input" type="checkbox" id="{{region.name}}CheckBox" v-model="grantPendingPaymentsReport">
                <label class="form-check-label" for="">{{ volunteer.resources.filter(r => r.id == 4)[0].description }}</label>
            </div>
            <div class="form-check form-switch" v-if="grantVolunteer && setPricesPermissionCheck && accessLevel == '1'">
                <input class="form-check-input" type="checkbox" id="{{region.name}}CheckBox" v-model="grantSetPrices">
                <label class="form-check-label" for="">{{ volunteer.resources.filter(r => r.id == 5)[0].description }}</label>
            </div>
            <div class="form-check form-switch" v-if="grantVolunteer && setTermsMailPermissionCheck && accessLevel == '1'">
                <input class="form-check-input" type="checkbox" id="{{region.name}}CheckBox" v-model="grantSetTermsMail">
                <label class="form-check-label" for="">{{ volunteer.resources.filter(r => r.id == 6)[0].description }}</label>
            </div>
            <div class="form-check form-switch" v-if="grantVolunteer && priceAdjustmentsCheck && accessLevel == '1'">
                <input class="form-check-input" type="checkbox" id="{{region.name}}CheckBox" v-model="grantPriceAdjustments">
                <label class="form-check-label" for="">{{ volunteer.resources.filter(r => r.id == 7)[0].description }}</label>
            </div>
            <div class="my-4" v-if="grantVolunteer">
                <button class="btn btn-primary" @click="createVolunteer">Save</button>
            </div>
        </div>
    </div>
    <div v-else>
        <LoadingSpinner />
    </div>
</div>
</template>

<script>
import axios from 'axios';
import {
    ref
} from 'vue';
import NavigationBar from '../components/NavigationBar.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue'

export default {
    name: "HomePage",
    components: {
        NavigationBar,
        LoadingSpinner,
    },

    setup() {
        const loading = ref(true)
        const memberId = ref(0);
        const volunteerName = ref("")
        const regionalOrLocalPermissions = ref(false)
        const accessLevel = ref("")
        const regionsAssigned = ref(null)
        const jamatkhanasAssigned = ref(null)
        const regionSelect = ref(0)
        const grantVolunteer = ref(false)
        const grantAddVolunteer = ref(false)
        const grantRunRepots = ref(false)
        const reportPermissionCheck = ref(false)
        const grantPendingPaymentsReport = ref(false)
        const grantSetPrices = ref(false)
        const grantSetTermsMail = ref(false)
        const grantPriceAdjustments = ref(false)
        const pendingPaymentReportCheck = ref(false)
        const setPricesPermissionCheck = ref(false)
        const setTermsMailPermissionCheck = ref(false)
        const priceAdjustmentsCheck = ref(false)
        const error = ref("")

        return {
            loading,
            memberId,
            volunteerName,
            accessLevel,
            regionalOrLocalPermissions,
            jamatkhanasAssigned,
            regionsAssigned,
            regionSelect,
            grantVolunteer,
            grantAddVolunteer,
            grantRunRepots,
            grantPendingPaymentsReport,
            grantSetPrices,
            grantSetTermsMail,
            grantPriceAdjustments,
            reportPermissionCheck,
            pendingPaymentReportCheck,
            setPricesPermissionCheck,
            setTermsMailPermissionCheck,
            priceAdjustmentsCheck,
            error,
        }
    },
    async created() {
        this.memberId = this.$store.getters.getSelectedMemberId;
        this.volunteer = this.$store.getters.getVolunteerInfo;
        if (!this.volunteer.resources.some(permission => permission.id === 2)) {
            this.$router.push("/search");
        }
        if (this.volunteer.resources.some(permission => permission.id === 3)) {
            this.reportPermissionCheck = true
        }
        if (this.volunteer.resources.some(permission => permission.id === 4)) {
            this.pendingPaymentReportCheck = true
        }
        if (this.volunteer.resources.some(permission => permission.id === 5)) {
            this.setPricesPermissionCheck = true
        }
        if (this.volunteer.resources.some(permission => permission.id === 6)) {
            this.setTermsMailPermissionCheck = true
        }
        if (this.volunteer.resources.some(permission => permission.id === 7)) {
            this.priceAdjustmentsCheck = true
        }
        if (this.volunteer.regions) {
            this.regionalOrLocalPermissions = true
        }
        const {
            data
        } = await axios.get("/api/v1/admin/volunteer/" + this.memberId);
        if (data.success) {
            let currentVolunteer = data.data;
            this.volunteerName = currentVolunteer.volunteer.name
            if (currentVolunteer.isVolunteer) {
                // todo route to edit
                this.$router.push('/volunteer/edit')
            }
        }
        this.loading = false;
    },
    methods: {
        clearValues() {
            this.regionSelect = 0;
            this.regionsAssigned = null;
            this.jamatkhanasAssigned = null;
        },
        resourceChecker() {
            let resources = [1]
            if (this.grantAddVolunteer == true) {
                resources.push(2)
            }
            if (this.grantRunReports == true) {
                resources.push(3)
            }
            if (this.grantPendingPaymentsReport == true) {
                resources.push(4)
            }
            if (this.grantSetPrices == true) {
                resources.push(5)
            }
            if (this.grantSetTermsMail == true) {
                resources.push(6)
            }
            if (this.grantPriceAdjustments == true) {
                resources.push(7)
            }
            return resources
        },
        async createVolunteer() {
            this.error = ""
            this.loading = true;
            try {
                let response = await axios.post('/api/v1/admin/volunteer', {
                    volunteerId: this.memberId,
                    regionIds: this.regionsAssigned ? [this.regionsAssigned] : null,
                    jamatkhanaIds: this.jamatkhanasAssigned ? [this.jamatkhanasAssigned] : null,
                    resourceIds: this.resourceChecker()
                });
                let {
                    success,
                    statusMessage
                } = response.data;
                if (!success) {
                    this.error = statusMessage;
                    this.loading = false;
                    return
                }
            } catch (err) {
                console.error(err);
                this.error = err;
                this.loading = false;
                return
            }
            this.$router.push('/volunteer/management')
        }
    },
    watch: {
        grantVolunteer() {
            this.grantAddVolunteer = false
            this.grantRunRepots = false
            this.grantPendingPaymentsReport = false
            this.grantSetPrices = false
            this.grantSetTermsMail = false
            this.grantPriceAdjustments = false
        }
    }
}
</script>

<style scoped>

</style>
