<template>
  <div class="d-flex flex-row align-items-center justify-content-center"
    style="top:0; bottom: 0; left: 0; right: 0; position: absolute">
    <div class="container">
      <div class="row">
        <div class="offset-sm-3 col-sm-6">
          <div class="card align-self-center flex-grow-1">
            <div class="card-header text-center" style="font-size: x-large">
              Funeral Expense Program
            </div>
            <div class="card-body">
              <div class="row">
                <div class="form-group offset-sm-2 col-sm-8">
                  <div class="h4 text-center">Login using Ismaili Institutions USA</div>
                </div>
              </div>
              <div class="row">
                <div class="form-group offset-sm-2 col-sm-8">
                  <div v-if="loading">
                   <LoadingSpinner />
                  </div>
                  <div v-else>
                  <a class="btn btn-success w-100" href="/api/auth/login" >
                      Login
                  </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading">
      <LoadingSpinner v-if="loading" :showOverlay="true"></LoadingSpinner>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import LoadingSpinner from '../components/LoadingSpinner.vue'

export default {
  components: {LoadingSpinner},
  // eslint-disable-next-line
  props:['login'],
  // eslint-disable-next-line
  setup(props){
    const store = useStore();
    const loading = computed(() => store.state.globalloading);

    return {
        loading,
    }
  }
}
</script>

<style scoped>
.btn-success{
  color: #fff;
  font-weight: bold;
}
</style>
