import { useStore } from 'vuex';

export const getMemberInfoFromID = (id, members) => {
    let foundMember = {
        firstName: null,
        lastName: null,
        relationToHead: null,
    }
    members.forEach(member => {
        if (String(member.memberId) === String(id)) {
            foundMember.firstName = member.firstName;
            foundMember.lastName = member.lastName;
            foundMember.relationToHead = member.relationshipToHeadOfHousehold;
        }
    })
    return foundMember;
}

export const displayInUSD = (num) => {
    try
    {
        return num.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
    catch (err) {
        console.log(err)
    }
    return '';
}

export const getDatefromUtcTimeStamp = (inputDateTime) => {
    try
    {
        return new Date(inputDateTime).toLocaleDateString('en-CA');
    }
    catch (err) {
        console.log(err)
    }
    return '';
}
export const getLocalTimestampfromUtcTimeStamp = (inputDateTime) => {
    try
    {
        return new Date(inputDateTime).toLocaleString();
    }
    catch (err) {
        console.log(err)
    }
    return '';
}

export const getDollarAndCents = (num) => {
    return Number(displayInUSD(num).replace('$','').replace(',',''))
}

export const replacePaymentId = (id, htmlString) => {
    return htmlString.replace("{{paymentId}}", id);
}

export const getSearchScope = (jkId, regionId) => {
    if (jkId) {
        return getJamatkhanaOrRegionName(jkId, "jamatkhana")
      } else if (regionId) {
        return getJamatkhanaOrRegionName(regionId, "region")
      } else {
        return "National"
      }
}

const getJamatkhanaOrRegionName = (id, type) => {
    const store = useStore();
    let methods = type === "region" ? store.getters.getMetadata.regions : store.getters.getMetadata.jamatkhanas;
    const filteredMethod = methods.filter(method => method.id === id);
    if (filteredMethod.length === 1) {
        return filteredMethod[0].name
    }
    return id;
}

export const isAppleTouch = () => {
    return /iPhone|iPad|iPod/.test(navigator.userAgent);
}

export const getVolunteerJks = () => {
    const store = useStore();
    const volunteerInfo = store.getters.getVolunteerInfo;
    let data = {
        jamatkhanas: [],
        selectedJamatkhana: placeholderValue
    }
    if (volunteerInfo.regions) {
        let jks = [];
        volunteerInfo.regions.forEach(region => {
            jks = [...jks, ...region.jamatkhanas]
        })
        data.jamatkhanas = jks;
    } else if (volunteerInfo.jamatkhanas) {
        data.jamatkhanas = volunteerInfo.jamatkhanas;
        if (volunteerInfo.jamatkhanas.length === 1) {
            data.selectedJamatkhana = data.jamatkhanas[0].id;
        }
    }
    // get the region id also
    let allJks = store.getters.getMetadata.jamatkhanas;
    for (let jk of data.jamatkhanas)
    {
        let filteredJk = allJks.filter(j => j.id === jk.id);
        if(filteredJk.length === 1)
        {
            jk['regionId'] = filteredJk[0].regionId;
        }
    }
    return data;
}

export const verifyHeadOfHouseHold = (members) => {
    let hohCount = 0;
    for (let fm of members) {
        if (fm.relationshipToHeadOfHousehold.id === 0) {
            hohCount++;
            try {
                const ageDifMs = Date.now() - Date.parse(fm.dateOfBirth)
                const ageDate = new Date(ageDifMs);
                const age = Math.abs(ageDate.getUTCFullYear() - 1970);
                if (age < 18) {
                    return "Head of Household must be at least 18 years old. Please select someone else as the Head of Household or enter a valid date of birth."
                }
            } catch {
                return "Unable to verify Head of Household's age. Please enter a valid date of birth."
            }
        }
    }
    if (hohCount > 1) {
        return "Household can only have one Head of Household.";
    }
    return "";
}

export const placeholderValue = "N/A";