<template>
<div>
    <NavigationBar />
    <div class="container" style="padding-top: 20px;">
        <div class="alert alert-success alert-dismissible d-flex align-items-center fade show" v-if="successMessage">
            <i class="bi-check-circle-fill"></i>
            <strong class="mx-2">Success!</strong> {{ successMessage }}
        </div>
        <div class="alert alert-danger alert-dismissible d-flex align-items-center fade show" v-if="error">
            <i class="bi-exclamation-octagon-fill"></i>
            <strong class="mx-2">Error!</strong> {{ error }}
            <br />
        </div>
        <h2>Batches Report</h2>
        <div class="searchInputs">
            <div class="searchInputRow">
                <div class="searchInputItem">
                    <label for="startDate" class="col-form-label"><b>From</b></label>
                    <input type="date" class="form-control" id="startDate" v-model="startDate" required>
                </div>
                <div class="searchInputItem">
                    <label for="endDate" class="col-form-label"><b>To</b></label>
                    <input type="date" class="form-control" id="endDate" v-model="endDate" required>
                </div>
            </div>
            <div class="searchInputRow">
                <div class="searchInputItem">
                    <button class="col-auto btn btn-success" :disabled="disableRunReport" @click="runReport">Search</button>
                </div>
            </div>
        </div>
        <div v-if="loadingReport">
            <LoadingSpinner />
        </div>
        <div v-if="report.length > 0 && !loadingReport" class="tableResults">
            <table class="table">
                <tbody>
                    <tr>
                        <td><b>Batch ID</b></td>
                        <td><b>Count</b></td>
                        <td><b>Total Amount</b></td>
                        <td><b>Created On</b></td>
                        <td><b>Created By</b></td>
                    </tr>
                    <tr v-for="batch in report" :key="batch.id">
                        <td><div class="link" @click="clickViewDetails(batch.id)">{{batch.id}}</div></td>
                        <td>{{batch.count}}</td>
                        <td>{{displayInUSD(batch.totalAmount)}}</td>
                        <td>{{getLocalTimestampfromUtcTimeStamp(batch.createdOn)}}</td>
                        <td>{{batch.createdBy.name}}</td>
                    </tr>
                </tbody>
            </table>
        </div>    
    </div>
</div>

</template>

<script>
import axios from 'axios';
import NavigationBar from '../components/NavigationBar.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import { displayInUSD, getLocalTimestampfromUtcTimeStamp } from '../utils/MethodUtils.js';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
    name: "BatchesReportPage",
    components: {
        NavigationBar,
        LoadingSpinner
    },
    setup() {
        const router = useRouter();
        const startDate = ref();
        const endDate = ref();
        const report = ref([]);

        const error = ref(null);
        const successMessage = ref(null);
        const loadingReport = ref(false);

        // default start and end dates to today
        startDate.value = new Date().toLocaleDateString('en-CA');
        endDate.value = new Date().toLocaleDateString('en-CA');

        const clickViewDetails = async (batchId) => {
            let routeData = router.resolve({name: 'BatchPaymentsReport', params: {batchId: batchId}});
            window.open(routeData.href, '_blank');
        }

        const runReport = async (disableErr) => {
            error.value = null;
            if (startDate.value > endDate.value) {
                error.value = "Start date cannot be later than end date";
                return;
            }
            loadingReport.value = true;

            // api on the other end is automatically doing the conversion for utc. 
            // start date works fine since it puts the time at local midnight
            // for this to be inclusive we just need to add +1 day to end date. 
            // this makes time be midnight of end date + 1.
            let inputEndDate = new Date((endDate.value + 'T00:00'));
            let newEndDate = new Date(inputEndDate.setDate(inputEndDate.getDate()+1)).toLocaleDateString('en-CA');
            let params = {
                startDate: startDate.value,
                endDate: newEndDate,
            }

            try {
                const response = await axios.get('/api/v1/admin/reports/batches', { params });
                const { success, statusMessage } = response.data;
                if (success) {
                    const { data } = response.data;
                    if (report.value.length === 0 && !disableErr) {
                        error.value = "No batches returned from the search criteria."
                    }
                    report.value = data;
                } else {
                    console.log(statusMessage);
                    error.value=statusMessage;
                }
            } catch (err) {
                console.log(err);
                error.value = err
            }

            loadingReport.value = false;
        }

        return {
            displayInUSD,
            getLocalTimestampfromUtcTimeStamp,
            loadingReport,
            clickViewDetails,
            runReport,
            error,
            successMessage,
            startDate,
            endDate,
            report,
        }

    },
    async created() {
        let volunteer = this.$store.getters.getVolunteerInfo;
        if (!volunteer.resources.some(permission => permission.id === 10)) {
            this.$router.push("/search");
        }
    }
}
</script>

<style scoped>
.searchInputs {
    padding-bottom: 20px;
}
.searchInputRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}
.searchInputRow:nth-last-child(2) {
    padding-bottom: 10px;
}
.searchInputItem {
    padding-right: 20px;
    min-width: 200px;
}
.link {
    color: #0d6efd;
    text-decoration: underline;
}
.link:hover {
    cursor: pointer;
}
.tableResults {
    padding-top: 20px;
}
button {
    margin-right: 10px;
}
.actionButtonRow {
    display: flex;
    flex-direction: row;
}
</style>
